import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/es';
import { Tooltip } from 'antd';
import { Colors } from '../../../constants/colors.constants.js';
import { CircleCheckIcon, WarningFilledIcon } from '../../../icons';
import { useSelector } from 'react-redux';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants.js';
import { trackingEvent } from '../../../analytics/index.js';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils.js';
import TooltipSystemV2 from '../../DesignSystem/TooltipSystemV2/TooltipSystemV2.js';
import {
  areWorkdaysValid,
  areShiftsValid,
  workdayHasMismatchingHours,
  parseShiftStringToArray,
  parseWorkdaysToBooleanArray
} from '../utils/shiftValidations.js';
import CalendarOptions from './CalendarOptions/CalendarOptions';
moment.locale('es');

export default function CalendarItem(props) {
  const calendarState = useSelector((state) => state.calendarState);
  const calendarSelected = calendarState.calendarSelected;
  const { item, handleClickItem, setCalendarsList, sector } = props;
  const { t } = useTranslation();
  const [hasWarning, setHasWarning] = useState(false);

  const trackDecimalDurationEvent = () => {
    trackingEvent(
      'calendars_alert_icon',
      {
        ...getBasicAmplitudEventProperties(),
        calendar_name: calendarState.calendarSelected?.name,
        calendar_id: calendarState.calendarSelected?.id
      },
      AMPLITUDE_SERVICE
    );
  };

  useEffect(() => {
    if (!sector || !item) return;

    const workDays = item?.working_days;
    const calendarShifts = item?.shifts;

    if (!areWorkdaysValid(workDays) || !areShiftsValid(calendarShifts)) {
      setHasWarning(true);
      trackDecimalDurationEvent();
      return;
    }

    const shifts = calendarShifts.map((shift) =>
      parseShiftStringToArray(shift.shift_string)
    );
    const weekDays = parseWorkdaysToBooleanArray(workDays);

    const hasMismatchingHours = workdayHasMismatchingHours({
      shifts,
      weekDays,
      hoursPerDay: sector.hoursPerDay
    });

    if (hasMismatchingHours) {
      setHasWarning(true);
      trackDecimalDurationEvent();
    }
  }, [item, sector]);

  return (
    <div
      className={
        calendarSelected && calendarSelected.id === item.id
          ? 'itemListGen active'
          : 'itemListGen'
      }>
      <div className="itemHeader">
        <div className="titleCalendar">
          {hasWarning && (
            <TooltipSystemV2
              title={`${t('master_plan_calendars.decimal_duration_warning')}`}
              placement="right">
              <div>
                <WarningFilledIcon />
              </div>
            </TooltipSystemV2>
          )}

          {item.name}
        </div>

        <div className="actions">
          <div>
            {`${t('master_plan_calendars.created_by')}: `}
            {item.user
              ? item.user.name + ' ' + item.user.lastname
              : t('master_plan_calendars.no_available_creator')}
          </div>
          <CalendarOptions
            calendarId={item.id}
            calendarName={item.name}
            isDefault={item.is_default}
            handleClickItem={handleClickItem}
            setCalendarsList={setCalendarsList}
          />
        </div>
      </div>
      <div className="itemSubTitle">
        <div className="subTitleCalendar">
          {item.sector && item.sector.project.name},{' '}
          {item.sector && item.sector.name}
        </div>
      </div>
      <div className="itemFooter">
        <div className="created">
          {`${t('master_plan_calendars.created')}: `}
          {moment(item.createdAt).format('LL')}
        </div>
        <div className="count">
          {item.is_default && (
            <Tooltip title="Calendario por defecto">
              <CircleCheckIcon color={Colors.DARK_MINT} />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
}

CalendarItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    sector: PropTypes.object,
    user: PropTypes.object,
    is_default: PropTypes.bool,
    createdAt: PropTypes.string,
    working_days: PropTypes.string,
    shifts: PropTypes.arrayOf(
      PropTypes.shape({
        shift_string: PropTypes.string
      })
    )
  }).isRequired,
  handleClickItem: PropTypes.func.isRequired,
  setCalendarsList: PropTypes.func.isRequired,
  sector: PropTypes.string.isRequired
};
