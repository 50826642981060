import React, { useState, useEffect } from 'react';

import checkedInverted from '../../assets/img/checked-inverted.png';
import uncheckedInverted from '../../assets/img/unchecked-inverted.png';

import checked from '../../assets/img/checked.png';
import unchecked from '../../assets/img/unchecked.png';

import './index.css';

export default function CustomCheckbox(props) {
  const { active, inverted, disabled = false } = props;
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (active) {
      setSelected(active);
    }
  }, [active]);

  const getIcon = () => {
    if (selected) {
      return <img width={8} src={inverted ? checkedInverted : checked} />;
    }
    return <img width={8} src={inverted ? uncheckedInverted : unchecked} />;
  };

  const handleClick = () => {
    if (!disabled) {
      setSelected(!selected);
      props.onClick();
    }
  };

  return (
    <span
      onClick={handleClick}
      className={`custom-check-box ${disabled ? 'disabled' : ''}`}
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
      {getIcon()}
    </span>
  );
}
