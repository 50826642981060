/* eslint-disable prefer-const */

/** React components  */
import React, { useEffect, useState, useRef, Fragment } from 'react';

import { CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

/** import library for format numbers */
import NumberFormat from 'react-number-format';

/** Import elements from library Antd */
import {
  Button,
  Modal,
  Tooltip,
  Radio,
  Table,
  Empty,
  DatePicker,
  Select,
  Row,
  Col,
  Input
} from 'antd';

/** component for edit cell */
import Editable from './Editable';

/** import library to scroll */
import scrollIntoView from 'scroll-into-view';

/** Redux */
import { useSelector, useDispatch } from 'react-redux';
import { calendarActions } from '../../../../redux/actions/calendarActions';
import { notifyMessage } from '../../../../utils/lookahead-common';
/** Moment for dates using */
import moment from 'moment';

import { withTranslation } from 'react-i18next';

import trashWorkingIcon from '../../../../assets/img/trash-workingday.png';

import { trackingEvent } from '../../../../analytics/index';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../../analytics/utils';

import { TrashOutlineIcon, PlusIcon } from '../../../../icons';
import { Colors } from '../../../../constants/colors.constants.js';

const { Option } = Select;

let hours = [...Array(24).keys()]; /** generate hours */
const newh = hours.map((e) => ({
  value: e,
  label: e < 10 ? `0${e}:00` : `${e}:00`
}));

function CalendarForm(props) {
  /** traduction */
  const { t, toDropWorkingDays } = props;
  /** redux */
  const calendarState = useSelector((state) => state.calendarState);
  const [modalData, setModalData] = useState({
    visible: false,
    exceptionState: null,
    index: false
  });
  const dispatch = useDispatch();
  const projectState = useSelector((state) => state.projectState);
  const sectorDateFormat = projectState.allSectors.find(
    (e) => e.id == projectState.sectorSelected
  );
  const formatDate =
    sectorDateFormat && sectorDateFormat.dateFormat
      ? sectorDateFormat.dateFormat
      : null;

  /** use props */
  const { add1Hour } = props;

  /** hooks */
  const [visibleIterate, setVisibleIterate] = useState(false);
  const [currentExceptionIndex, setCurrentExceptionIndex] = useState(0);

  /** render hours conditionally */
  const renderOptions = (currentState, index = 0, selectName = null) => {
    let conditionHour = -1;
    const currentExceptions = currentState.exceptions;
    if (selectName === 'hourend') {
      if (currentExceptions[index]) {
        if (currentExceptions[index].hourini) {
          conditionHour = currentExceptions[index].hourini
            ? add1Hour(currentExceptions[index].hourini, 1)
            : -1;
        } else if (currentExceptions[index].shift_start) {
          conditionHour = add1Hour(
            parseInt(currentExceptions[index].shift_start.split('-')[0]),
            1
          );
        }
      }
    }
    let options = newh
      .sort() /** generate Select Hours */
      .map((hour, index) => {
        let disabledOption = false;
        if (index < conditionHour) {
          disabledOption = true;
        }
        return (
          <Option disabled={disabledOption} key={index} value={hour.value}>
            {hour.label}
          </Option>
        );
      });
    return options;
  };

  const disabledDate = (current, index) => {
    const lim = moment(stateExceptions[index].from_date);
    return current && lim && current < lim;
  };

  /** config columns table */
  const columnsTemplate = [
    {
      title: t('master_plan_calendars.name'),
      dataIndex: 'name',
      key: 'name',
      render: (t, row, index) => (
        <Editable
          text={stateExceptions[index].name}
          placeholder="Name"
          childRef={inputRef}
          type="input"
          className="input-table-edit">
          <input
            ref={inputRef}
            type="text"
            name="task"
            className="inputInline"
            placeholder="Name"
            value={stateExceptions[index].name}
            onChange={(e) => handleWrite(e, row, index)}
            autoComplete="off"
          />
        </Editable>
      )
    },
    {
      title: t('master_plan_calendars.init'),
      dataIndex: 'start',
      key: 'start',
      render: (text, record, index) => (
        <div className="dateField">
          <DatePicker
            size="default"
            allowClear={false}
            suffixIcon
            format={dateFormatException}
            value={moment.utc(record.from_date).startOf('day')}
            onChange={(e) => handleChangeField(e, record, index, 'from_date')}
          />
        </div>
      )
    },
    {
      title: t('master_plan_calendars.end'),
      key: 'end',
      dataIndex: 'end',
      render: (text, record, index) => (
        <div className="dateField">
          <DatePicker
            size="default"
            disabledDate={(e) => disabledDate(e, index)}
            suffixIcon
            allowClear={false}
            format={dateFormatException}
            value={moment.utc(record.to_date).startOf('day')}
            onChange={(e) => handleChangeField(e, record, index, 'to_date')}
          />
        </div>
      )
    },

    {
      title: t('master_plan_calendars.workeable'),
      dataIndex: 'holiday',
      key: 'holiday',
      render: (t, record, index) => (
        <div className="swicth-day">
          <Radio.Group
            value={
              stateExceptions[index] ? stateExceptions[index].workable : false
            }
            buttonStyle="solid"
            size="small"
            onChange={(e) =>
              handleChangeFieldWork(e, record, index, 'workable')
            }>
            <Radio.Button
              onClick={() => {
                stateExceptions[index].workingDays = [];
              }}
              className="btnSwitch table-switch"
              value={false}>
              {props.t('calendars_form.not')}
            </Radio.Button>
            <Radio.Button
              onClick={() =>
                setModalData({
                  visible: true,
                  exceptionState: stateExceptions[index],
                  index: index
                })
              }
              className="btnSwitch table-switch"
              value={true}>
              {props.t('calendars_form.yes')}
            </Radio.Button>
          </Radio.Group>
        </div>
      )
    },
    {
      title: t('master_plan_calendars.journal'),
      dataIndex: 'hourini',
      key: 'hourini',
      render: (t, record, index) => {
        if (!stateExceptions[index]) return null;
        if (!stateExceptions[index].workable) {
          return '-';
        } else if (stateExceptions[index].workingDays.length) {
          return props.t('ĺang') === 'es' ? 'Asignada' : 'Assigned';
        }
        return props.t('ĺang') === 'es' ? 'No Asignada' : 'Not assigned';
      }
    },
    {
      title: t('master_plan_calendars.action'),
      key: 'action',
      width: '15%',
      align: 'center',
      render: (text, record, index) => (
        <span className="actionsIcons">
          &nbsp;
          <Tooltip title="Delete">
            <a onClick={(e) => handleDelete(e, record, index)}>
              <TrashOutlineIcon
                color={Colors.BLACK}
                className="exceptionsActions"
              />
            </a>
          </Tooltip>
        </span>
      )
    }
  ];

  const columns = columnsTemplate.map((e) => ({
    ...e,
    align: 'center'
  }));

  const inputRef = useRef();
  const [stateExceptions, setStateExceptions] = useState([]);

  const getSortedExceptions = (exceptionList) =>
    (exceptionList ?? []).slice().sort((a, b) => {
      const dateA = new Date(a.to_date);
      const dateB = new Date(b.to_date);
      return dateA - dateB;
    });

  useEffect(() => {
    if (calendarState.calendarForm) {
      const sortedStateExceptions = getSortedExceptions(
        calendarState.calendarForm.exceptions
      );
      setStateExceptions(
        sortedStateExceptions.map((e, index) => ({
          ...e,
          key: index
        }))
      );
    }
  }, [calendarState.calendarForm]);

  /** Similar to did mount */
  useEffect(() => {
    if (calendarState.calendarSelected) {
      const newExceptions = calendarState.calendarSelected.exceptions.map(
        (e, index) => ({
          ...e,
          key: index
        })
      );
      setStateExceptions(newExceptions);
      dispatch(
        calendarActions.setCalendarForm({
          ...calendarState.calendarForm,
          exceptions: newExceptions
        })
      );
    }
  }, [calendarState.calendarSelected]);

  const dateFormatWithoutHour = (dateFormat) => dateFormat.split(' ')[0];

  const dateFormatException = dateFormatWithoutHour(formatDate);

  const handleDelete = (e, record, i) => {
    const newState = stateExceptions.filter((e, index) => index !== i);

    trackingEvent(
      'exceptions_deletion',
      {
        ...getBasicAmplitudEventProperties(),
        event_source: calendarState.calendarSelected
          ? 'calendar_edition'
          : 'calendar_creation',
        calendar_name:
          calendarState.calendarSelected?.name ??
          calendarState.calendarForm.name,
        calendar_id: calendarState.calendarSelected?.id ?? '',
        exception_id: calendarState.calendarForm.exceptions[i].id ?? ''
      },
      AMPLITUDE_SERVICE
    );

    dispatch(
      calendarActions.setCalendarForm({
        ...calendarState.calendarForm,
        exceptions: newState
      })
    );
  };

  const handleAddExceptionDay = (e) => {
    let tmpRow = {
      key: moment().toISOString(),
      name: t('calendars_form.name_exc'),
      workable: false,
      every_frecuency: 1,
      every_type: 'year',
      iteration_end: 1,
      iteration_end_value: 'never',
      hourini: 'Start',
      hourend: 'End',
      from_date: moment(),
      to_date: moment(),
      workingDays: []
    };

    /** Set new State */
    const newState = stateExceptions;
    newState.push(tmpRow);
    const sortedNewState = getSortedExceptions(newState);

    dispatch(
      calendarActions.setCalendarForm({
        ...calendarState.calendarForm,
        exceptions: sortedNewState
      })
    );
    handleScroll();

    trackingEvent(
      'exceptions_creation',
      {
        ...getBasicAmplitudEventProperties(),
        event_source: calendarState.calendarSelected
          ? 'calendar_edition'
          : 'calendar_creation',
        calendar_name:
          calendarState.calendarSelected?.name ??
          calendarState.calendarForm.name,
        calendar_id: calendarState.calendarSelected?.id ?? ''
      },
      AMPLITUDE_SERVICE
    );
  };

  const handleChangeField = (e, record, index, nameDate) => {
    const newState = stateExceptions;
    newState[index][nameDate] = e;
    if (nameDate === 'from_date') {
      newState[index].to_date = e;
    }
    dispatch(
      calendarActions.setCalendarForm({
        ...calendarState.calendarForm,
        exceptions: newState
      })
    );
    if (nameDate.includes('ini')) {
      handleChangeField(
        add1Hour(e, 1),
        record,
        index,
        nameDate.replace(/ini/gi, 'end')
      );
    }
  };

  const handleChangeFieldWork = (e, record, index, nameDate) => {
    const newState = stateExceptions;
    newState[index][nameDate] = e.target.value;
    if (e.target.value) {
      setModalData({ visible: true, exceptionState: newState[index], index });
    } else if (!e.target.value) {
      newState[index].workingDays = [];
    }

    dispatch(
      calendarActions.setCalendarForm({
        ...calendarState.calendarForm,
        exceptions: newState
      })
    );
  };

  const handleWrite = (e, row, index) => {
    /** update Row */
    const tmpRow = row;
    tmpRow.name = e.target.value;

    /** Set new State */
    const newState = stateExceptions;
    newState[index] = tmpRow;

    dispatch(
      calendarActions.setCalendarForm({
        ...calendarState.calendarForm,
        exceptions: newState
      })
    );
  };

  const handleScroll = () => {
    scrollIntoView(document.querySelector('.scroll-row'), {
      align: {
        top: 0
      }
    });
  };

  const handleClose = () => {
    setVisibleIterate(false);
  };

  const handleContinue = () => {
    console.dir('save iteration');
  };

  const handleCancelIterate = () => {
    setVisibleIterate(false);
  };

  const renderIterate = () => (
    <Modal
      closable={true}
      className="frm-iterate"
      title=""
      visible={visibleIterate}
      centered
      keyboard={false}
      maskClosable={false}
      onCancel={handleClose}
      width={300}
      footer={
        /** submit button in footer */
        <div className="footerBtns" key="btns">
          <a
            href="#/"
            onClick={(e) => handleCancelIterate(e)}
            className="refLink skipLink">
            {t('calendars_form.cancel')}
          </a>
          <Button onClick={handleContinue} className="btn-iteration">
            {t('calendars_form.save')}
          </Button>
        </div>
      }>
      <div className="title-frm-add">
        Custom Iteration
        <div className="title-close" onClick={handleClose}>
          <CloseOutlined />
        </div>
      </div>
      <div className="row-iterate">
        <div className="row-title">Repeat every</div>
        <NumberFormat
          id="numberformatProjectSize"
          name="psize"
          className="fixOnlyNumber input-every"
          placeholder="1"
          maxLength="2"
          displayType={'input'}
          prefix={''}
          decimalSeparator={false}
          value={
            stateExceptions[currentExceptionIndex]
              ? stateExceptions[currentExceptionIndex].every_frecuency
              : 1
          }
          onChange={(e) => {
            const newState = calendarState.calendarForm.exceptions;
            newState[currentExceptionIndex].every_frecuency = e.target.value;
            dispatch(
              calendarActions.setCalendarForm({
                ...calendarState.calendarForm,
                exceptions: newState
              })
            );
          }}
        />
        <Select
          placeholder="Every"
          className="select-iterate"
          style={{ width: 90 }}
          value={
            stateExceptions[currentExceptionIndex]
              ? stateExceptions[currentExceptionIndex].every_type
              : 'Every'
          }
          onChange={(e) => {
            const newState = calendarState.calendarForm.exceptions;
            newState[currentExceptionIndex].every_type = e;
            dispatch(
              calendarActions.setCalendarForm({
                ...calendarState.calendarForm,
                exceptions: newState
              })
            );
          }}>
          <Option value="day">Day</Option>
          <Option value="week">Week</Option>
          <Option value="month">Month</Option>
          <Option value="year">Year</Option>
        </Select>
      </div>

      <div className="row-iterate">
        <div className="row-title">Finish</div>
      </div>

      <div className="row-iterate">
        <Radio.Group
          value={
            stateExceptions[currentExceptionIndex]
              ? stateExceptions[currentExceptionIndex].iteration_end
              : 1
          }
          onChange={(e) => {
            const newState = calendarState.calendarForm.exceptions;
            newState[currentExceptionIndex].iteration_end = e.target.value;
            dispatch(
              calendarActions.setCalendarForm({
                ...calendarState.calendarForm,
                exceptions: newState
              })
            );
          }}>
          <Radio className="end-option" value={1}>
            Never
          </Radio>
          <Radio className="end-option" value={2}>
            <div className="option-label">At</div>
            <DatePicker
              size="default"
              allowClear={false}
              suffixIcon
              format={dateFormatException}
              defaultValue={moment()}
              style={{ width: 100 }}
              className="date-iterate"
            />
          </Radio>
          <Radio className="end-option" value={3}>
            <div className="option-label">After</div>
            <NumberFormat
              id="numberformatProjectSize"
              name="psize"
              className="fixOnlyNumber"
              placeholder="1"
              maxLength="2"
              displayType={'input'}
              prefix={''}
              decimalSeparator={false}
            />
            Reps
          </Radio>
        </Radio.Group>
      </div>
    </Modal>
  );

  /** handle open modal iterate options  */
  const handleAddIterate = (index) => {
    setVisibleIterate(true);
    setCurrentExceptionIndex(index);
  };

  const renderHoursForWorking = (shift, selectType) => {
    const workingDays = modalData.exceptionState.workingDays;
    let conditionHour = -1;
    if (selectType == 'start') {
      if (shift.correlative_id) {
        conditionHour = add1Hour(
          workingDays[shift.correlative_id - 1].shift_end,
          1
        );
        shift.conditionHourIni = conditionHour;
      }
    } else if (selectType == 'end') {
      conditionHour = add1Hour(shift.shift_ini, 1);
      shift.conditionHourEnd = conditionHour;
    }

    let options = newh
      .sort() /** generate Select Hours */
      .map((hour, index) => {
        let disabledOption = false;
        if (index < conditionHour) {
          disabledOption = true;
        }
        return (
          <Option disabled={disabledOption} key={index} value={hour.value}>
            {hour.label}
          </Option>
        );
      });
    return options;
  };

  const handleChangeHoursWorkingDays = (value, shiftType, workingDayIndex) => {
    const workingDays = modalData.exceptionState.workingDays;
    if (shiftType == 'start') {
      workingDays[workingDayIndex].shift_ini = value;
    } else if (shiftType == 'end') {
      workingDays[workingDayIndex].shift_end = value;
    }
    const reference = workingDays[workingDayIndex];
    const totalWorkingDays = workingDays.length;

    for (let i = reference.correlative_id; i < totalWorkingDays; i++) {
      if (i == reference.correlative_id) {
        if (shiftType == 'start') {
          workingDays[i].shift_end = false;
        }
      } else {
        workingDays[i].shift_ini = false;
        workingDays[i].shift_end = false;
      }
    }

    // dispatch(calendarActions.setCalendarForm({ ...calendarState.calendarForm, workingDays: workingDays }))
    // setWorkingDays([...workingDays])
    setModalData({
      ...modalData,
      exceptionState: { ...modalData.exceptionState }
    });
  };

  const onCancelWorkingDayModal = () => {
    const reference = calendarState.calendarForm.exceptions[modalData.index];
    if (!reference.workingDays.length) {
      reference.workable = false;
    } else {
      let nonSettedTime = false;
      reference.workingDays.map((shift) => {
        if (!shift.shift_ini || !shift.shift_end) {
          nonSettedTime = true;
        }
      });
      if (nonSettedTime) {
        reference.workingDays = [];
        reference.workable = false;
      }
    }

    setStateExceptions([...stateExceptions]);
    setModalData({ visible: false, exceptionState: false, index: false });
  };

  const deleteShift = (index) => {
    const toDelete = modalData.exceptionState.workingDays[index];
    if (toDropWorkingDays) {
      toDropWorkingDays.push(toDelete);
    }
    const arrayWithoutElementToDelete =
      modalData.exceptionState.workingDays.filter((el) => toDelete != el);
    arrayWithoutElementToDelete.map((el, index) => {
      el.correlative_id = index;
    });

    setModalData({
      ...modalData,
      exceptionState: {
        ...modalData.exceptionState,
        workingDays: arrayWithoutElementToDelete
      }
    });
  };

  const handleSaveWorkingDays = () => {
    const reference = calendarState.calendarForm.exceptions[modalData.index];
    let nonSettedTime = false;
    if (!reference.workingDays.length) {
      reference.workable = false;
    }
    reference.workingDays.map((shift) => {
      if (!shift.shift_ini || !shift.shift_end) {
        nonSettedTime = true;
      }
    });
    if (nonSettedTime) {
      notifyMessage({
        title: 'Configuración de horarios incorrecta',
        message: 'Debes configurar horarios coherentes a tus jornadas.',
        type: 'error'
      });
    } else {
      setModalData({ visible: false, exceptionState: false, index: false });
    }
  };

  const CustomEmptyComponent = () => (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <span>{t('master_plan_calendars.exception_table_no_data')}</span>
      }
    />
  );

  /** render */
  if (!formatDate) return <div></div>;

  return (
    <Fragment>
      <Table
        className="table-items"
        columns={columns}
        dataSource={stateExceptions}
        locale={{
          emptyText: <CustomEmptyComponent />
        }}
        pagination={false}
        scroll={{ y: 300 }}
        rowClassName={(_, index) =>
          index === stateExceptions.length - 1 ? 'scroll-row' : ''
        }
      />
      <Row>
        <Col span={5}>
          <div className="addExcDay" onClick={(e) => handleAddExceptionDay(e)}>
            <PlusIcon className="plusIcon" /> {t('master_plan_calendars.add')}
          </div>
        </Col>
      </Row>
      {renderIterate()}

      <Modal
        closable={false}
        className="frmCalendar"
        title=""
        visible={modalData.visible}
        centered
        keyboard={false}
        maskClosable={false}
        onCancel={onCancelWorkingDayModal}
        width={600}
        bodyStyle={{ overflow: 'auto' }}
        footer={
          /** submit button in footer */
          <div className="footerBtns" key="btns">
            <Button onClick={handleSaveWorkingDays} className="btnSubmit">
              {props.t('ĺang') === 'es' ? 'Guardar' : 'Save'}
            </Button>
          </div>
        }>
        <div className="title-frm-add">
          {props.t('ĺang') === 'es'
            ? 'Jornada Excepción Laboral'
            : 'Specify exception hours'}
          <div className="title-close" onClick={onCancelWorkingDayModal}>
            <CloseOutlined />
          </div>
        </div>

        {modalData.exceptionState ? (
          <Row>
            <Col>
              <Row style={{ marginTop: 10 }}>
                <Col span={8}>{modalData.exceptionState.name}</Col>
                <Col
                  offset={6}
                  span={10}
                  style={{ textAlign: 'right', color: '#2C3421' }}>
                  {modalData.exceptionState.from_date.format
                    ? modalData.exceptionState.from_date.format(formatDate)
                    : moment(modalData.exceptionState.from_date).format(
                        formatDate
                      )}{' '}
                  -{' '}
                  {modalData.exceptionState.to_date.format
                    ? modalData.exceptionState.to_date.format(formatDate)
                    : moment(modalData.exceptionState.to_date).format(
                        formatDate
                      )}
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
        {modalData.exceptionState
          ? modalData.exceptionState.workingDays.map((shift, key) => (
              <Row
                key={key}
                className="frm frm-calendar"
                style={{ minHeight: 0, height: '3%' }}>
                <Col offset={4} span={4} style={{ color: '#7DFF8A' }}>
                  {shift.correlative_id + 1}
                  {props.t('lang') === 'es'
                    ? '°'
                    : shift.correlative_id + 1 === 1
                      ? 'st '
                      : shift.correlative_id + 1 === 2
                        ? 'nd '
                        : shift.correlative_id + 1 === 3
                          ? 'rd '
                          : 'th '}
                  {props.t('ĺang') === 'es' ? 'Jornada' : 'Shift'}
                </Col>

                <div className="div-day" style={{ width: '49%' }}>
                  <div className="day-shift">
                    {/** Start hour select */}
                    <Select
                      dropdownClassName="dropdown-hours-style"
                      bordered={false}
                      value={
                        shift.shift_ini === false
                          ? 'Start'
                          : parseInt(shift.shift_ini)
                      }
                      className="shiftSelect"
                      onChange={(e) =>
                        handleChangeHoursWorkingDays(e, 'start', key)
                      }>
                      {renderHoursForWorking(shift, 'start')}
                    </Select>

                    {/** End hour select */}
                    <Select
                      dropdownClassName="dropdown-hours-style"
                      bordered={false}
                      value={
                        shift.shift_end === false
                          ? 'End'
                          : parseInt(shift.shift_end)
                      }
                      className="shiftSelect"
                      onChange={(e) =>
                        handleChangeHoursWorkingDays(e, 'end', key)
                      }>
                      {renderHoursForWorking(shift, 'end')}
                    </Select>
                  </div>
                </div>
                <Col span={2}>
                  <span
                    style={{ marginLeft: 5, cursor: 'pointer' }}
                    onClick={() => deleteShift(key)}>
                    <img
                      className="delete-icon"
                      src={trashWorkingIcon}
                      width={15}
                    />
                  </span>
                </Col>
              </Row>
            ))
          : null}

        <Row>
          <Col
            span={4}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              modalData.exceptionState.workingDays.push({
                shift_ini: false,
                shift_end: false,
                correlative_id: modalData.exceptionState.workingDays.length
              });
              setModalData({
                ...modalData,
                exceptionState: { ...modalData.exceptionState }
              });
            }}>
            {props.t('ĺang') === 'es' ? '+ Agregar' : '+ Add'}
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
}

export default withTranslation()(CalendarForm);
