import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const LabelSystem = ({
  theme = 'light',
  htmlFor,
  children,
  isError = false,
  className,
  ...props
}) => {
  if (!children) {
    return null;
  }

  return (
    <label
      className={cn(
        'label-design-system',
        `theme--${theme}`,
        { error: isError },
        className
      )}
      htmlFor={htmlFor}
      {...props}>
      {children}
    </label>
  );
};

LabelSystem.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  htmlFor: PropTypes.string,
  children: PropTypes.node,
  isError: PropTypes.bool,
  className: PropTypes.string,
  props: PropTypes.object
};

export default LabelSystem;
