export const hasNoWorkingDaysSelected = (weekDays) =>
  weekDays.every((isWorkingDay) => !isWorkingDay);

export const hasNoShifts = (shifts) => !shifts || shifts.length === 0;

export const workdayHasMismatchingHours = ({ weekDays, shifts, hoursPerDay }) =>
  weekDays.some((isWorkingDay, index) =>
    hasMismatchingWorkingHours(isWorkingDay, index, shifts, hoursPerDay)
  );

export const formHasShiftsWithoutHours = ({ weekDays, shifts }) =>
  weekDays.some((isWorkingDay, index) =>
    checkIfSomeHoursAreNotDefined(isWorkingDay, index, shifts)
  );

export const areShiftsValid = (workingDays) => {
  if (!workingDays) {
    return false;
  }
  return workingDays.some((shift) => isShiftStringValid(shift.shift_string));
};

export const areWorkdaysValid = (workdays) => {
  if (typeof workdays !== 'string') {
    return false;
  }
  if (workdays.length === 0) {
    return false;
  }
  const daysArray = workdays.split(',');
  if (daysArray.length < 7) {
    return false;
  }
  return daysArray.every((day) => day.trim() !== '');
};

export const parseShiftStringToArray = (shiftString) => {
  const [startDaysString, endDaysString] = shiftString.split('-');
  const shiftIni = startDaysString
    .split(',')
    .map((hour) => (hour === 'false' ? false : parseInt(hour.split(':')[0])));
  const shiftEnd = endDaysString
    .split(',')
    .map((hour) => (hour === 'false' ? false : parseInt(hour.split(':')[0])));
  return {
    shift_ini: shiftIni,
    shift_end: shiftEnd
  };
};

export const parseWorkdaysToBooleanArray = (daysString) =>
  daysString.split(',').map((day) => day !== '0');

const hasMismatchingWorkingHours = (
  isWorkingDay,
  dayIndex,
  shifts,
  hoursPerDay
) => {
  if (!isWorkingDay) return false;
  const totalHours = shifts.reduce(
    (sum, { shift_ini: shiftStart, shift_end: shiftEnd }) => {
      const start = shiftStart[dayIndex];
      const end = shiftEnd[dayIndex];
      return sum + getShiftDuration(start, end);
    },
    0
  );
  return totalHours !== hoursPerDay;
};

const checkIfSomeHoursAreNotDefined = (isWorkingDay, dayIndex, shifts) => {
  if (!isWorkingDay) {
    return false;
  }
  if (!shifts) {
    return true;
  }
  return shifts.some(
    ({ shift_ini: shiftStart, shift_end: shiftEnd }) =>
      shiftStart[dayIndex] === false || shiftEnd[dayIndex] === false
  );
};

const getShiftDuration = (start, end) => {
  const shiftDuration = end - start;
  return shiftDuration < 0 ? 0 : shiftDuration;
};

const isShiftStringValid = (shiftString) => {
  if (typeof shiftString !== 'string') {
    return false;
  }
  const hoursConfigs = shiftString.split('-');
  if (hoursConfigs.length !== 2) {
    return false;
  }
  const [startHours, endHours] = hoursConfigs;
  if (!startHours || !endHours) {
    return false;
  }
  if (startHours === 'null' || endHours === 'null') {
    return false;
  }
  const startHoursArray = startHours.split(',');
  const endHoursArray = endHours.split(',');
  if (areHoursInconsistent(startHoursArray, endHoursArray)) {
    return false;
  }
  if (startHoursArray.length < 7 || endHoursArray.length < 7) {
    return false;
  }
  return true;
};

const areHoursInconsistent = (startHours, endHours) =>
  startHours.some((start, i) => isStartGreaterOrEqualToEnd(start, endHours[i]));

const isStartGreaterOrEqualToEnd = (start, end) => {
  if (start === 'false' && end !== 'false') {
    return true;
  }
  if (end === 'false' && start !== 'false') {
    return true;
  }
  const [sH, sM] = start.split(':').map(Number);
  const [eH, eM] = end.split(':').map(Number);
  return sH * 60 + sM >= eH * 60 + eM;
};
