import React from 'react';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/actions/userActions';
import { useUserMutation } from '../../hooks/userMutation';
import UserAvatar from './Avatar';
import PropTypes from 'prop-types';
import notificationSystemV2 from '../DesignSystem/NotificationSystemV2/NotificationSystemV2';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';

const EditableAvatar = ({ user, size, className }) => {
  const dispatch = useDispatch();
  const { isUpdating, handleImageChange } = useUserMutation(user);
  const { t } = useTranslation();
  return (
    <ImgCrop rotate shape="round" modalWidth={850}>
      <Upload
        customRequest={async ({ file, onError }) => {
          const imageUrl = URL.createObjectURL(file);
          try {
            dispatch(userActions.setUserUpdateImage(imageUrl));
            await handleImageChange(file);
          } catch (error) {
            URL.revokeObjectURL(imageUrl);
            dispatch(userActions.setUserUpdateImage(null));
            notificationSystemV2({
              key: 'error-uploading-image',
              type: 'error',
              message: t('user_config.avatar.error_uploading_image')
            });
            onError(error);
          }
        }}
        listType="picture-card"
        showUploadList={false}
        disabled={isUpdating}>
        <UserAvatar size={size} className={className} />
        {isUpdating && <LoadingOutlined />}
      </Upload>
    </ImgCrop>
  );
};

EditableAvatar.propTypes = {
  user: PropTypes.object.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string
};

EditableAvatar.defaultProps = {
  size: 100,
  className: ''
};

export default EditableAvatar;
