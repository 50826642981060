import React from 'react';
import { Avatar } from 'antd';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../utils/userUtils';
import PropTypes from 'prop-types';

const UserAvatar = React.memo(({ className, size }) => {
  const userImage = useSelector((state) => state.userState.user?.image);
  const loggedUser = getCurrentUser();
  const avatarSrc = userImage || loggedUser?.image;

  return (
    <Avatar
      className={`avatar-btn link-account ${className || ''}`}
      shape="circle"
      size={size}
      icon="user"
      src={avatarSrc}
    />
  );
});

UserAvatar.displayName = 'UserAvatar';
UserAvatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
UserAvatar.defaultProps = {
  className: '',
  size: 45
};

export default UserAvatar;
