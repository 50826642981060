/* eslint-disable prefer-const */

/** React components  */
import React, { useEffect, useState } from 'react';

/** Import elements from library Antd */
import { CloseOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Row } from 'antd';

/** import common functions from utils */
import { openNotification } from '../../../utils';

/** Redux implementation */
import { useDispatch, useSelector } from 'react-redux';
import { calendarActions } from '../../../redux/actions/calendarActions';

/** Services */
import {
  calendarExceptionDayService,
  calendarService,
  ganttService,
  workingdayService
} from '../../../services';

import cloneDeep from 'lodash/cloneDeep';

/** import components */
import { withTranslation } from 'react-i18next';
import FormDays from '../../../components/Calendar/CalendarForm/FormDays';
import FormExceptionsDays from '../../../components/Calendar/CalendarForm/FormExceptionsDays';

import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { trackingEvent } from '../../../analytics/index';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';

import AlertSystem from '../../DesignSystem/AlertSystem';

import {
  formHasShiftsWithoutHours,
  hasNoShifts,
  hasNoWorkingDaysSelected,
  workdayHasMismatchingHours
} from '../utils/shiftValidations';
import { mismatchedMessageWithLink } from '../utils/knowledgebaseUtils';

function CalendarForm(props) {
  /** traduction */
  const { t } = props;
  /** use props */
  const {
    visibleForm,
    setVisibleForm,
    formatShift,
    add1Hour,
    calendarsList,
    sector
  } = props;

  /** Redux */
  const dispatch = useDispatch();
  const projectState = useSelector((state) => state.projectState);
  const calendarState = useSelector((state) => state.calendarState);
  const nameCalendar = calendarState.calendarForm
    ? calendarState.calendarForm.name
    : null;
  const alertCalendarEditSuccess = {
    /** notification exit */ title: t('calendars_form.title'),
    description: t('calendars_form.add'),
    type: 'success'
  };

  const loggedUser = JSON.parse(localStorage.getItem('user'));

  const defaultCalendar = {
    workingDays: [
      {
        shift_ini: [false, 8, 8, 8, 8, 8, false],
        shift_end: [false, 16, 16, 16, 16, 16, false],
        correlative_id: 0
      }
    ],
    days: [false, true, true, true, true, true, false]
  };

  /** Hooks */
  const [isShiftsActive, setIsShiftsActive] = useState(false);
  const [currenTab, setCurrenTab] = useState(1); /** handle tabs (1, 2, 3) */
  const [currentGantt, setCurrentGantt] = useState();
  const [loading, setLoading] = useState(false); /** handle load */
  const [showShiftWarning, setShowShiftWarning] = useState(false);

  /** load data */
  useEffect(() => {
    loadGantt();
  }, []);

  const getGantt = async () => {
    const gannt = await ganttService.showBySector(projectState.sectorSelected);
    return gannt;
  };

  const loadGantt = async () => {
    const gantt = await getGantt();
    if (gantt) {
      setCurrentGantt(gantt.gantt.id);
    }
  };

  const trackCalendarCreationWarnings = (reason) => {
    trackingEvent(
      'calendar_warning_shown',
      {
        ...getBasicAmplitudEventProperties(),
        event_source: 'calendar_creation',
        reason
      },
      AMPLITUDE_SERVICE
    );
  };

  /** services */
  const createCalendar = async (data) => {
    const calendarCreated = await calendarService.create(data);
    return calendarCreated;
  };

  const createExceptionDay = async (data) => {
    const excCreated = await calendarExceptionDayService.create(data);
    return excCreated;
  };

  const getCalendars = async () => {
    const calendars = await calendarService.showBySector(
      projectState.sectorSelected
    );
    return calendars;
  };

  /** Component Logic */
  const resetStateCalendar = () => {
    setCurrenTab(1);
    setVisibleForm(false);
    setShowShiftWarning(false);
  };

  /** handle tabs / submit data */
  const handleContinue = async () => {
    const start_ini = calendarState.calendarForm.shiftStart_ini
      ? formatShift(calendarState.calendarForm.shiftStart_ini)
      : null;
    const start_end = calendarState.calendarForm.shiftStart_end
      ? formatShift(calendarState.calendarForm.shiftStart_end)
      : null;
    const end_ini = calendarState.calendarForm.shiftEnd_ini
      ? formatShift(calendarState.calendarForm.shiftEnd_ini)
      : null;
    const end_end = calendarState.calendarForm.shiftEnd_end
      ? formatShift(calendarState.calendarForm.shiftEnd_end)
      : null;

    const is_default = !calendarsList.length;
    const working_days = calendarState.calendarForm.days
      .map((eld) => eld | 0)
      .join();

    if (currenTab === 1 || currenTab === 2) {
      if (!calendarState.calendarForm.name) {
        dispatch(
          calendarActions.setCalendarForm({
            ...calendarState.calendarForm,
            name: ''
          })
        );
        return false;
      }
    }
    if (currenTab === 1) {
      trackingEvent(
        'set_calendar_name',
        {
          ...getBasicAmplitudEventProperties(),
          event_source: 'calendar_creation'
        },
        AMPLITUDE_SERVICE
      );
      setCurrenTab(currenTab + 1);
    }

    if (currenTab === 2) {
      setShowShiftWarning(false);
      const { days, workingDays: shifts } = calendarState.calendarForm;
      const calendarData = { weekDays: days, shifts };
      const { hoursPerDay } = sector;

      if (hasNoWorkingDaysSelected(days)) {
        trackCalendarCreationWarnings('no_working_days_selected');
        openNotification({
          title: t('calendars_form.title'),
          description: t('calendars_form.select_day'),
          type: 'error'
        });
        return false;
      }

      if (hasNoShifts(shifts)) {
        trackCalendarCreationWarnings('no_shift_created');
        openNotification({
          title: t('calendars_form.title'),
          description: t('calendars_form.no_shift_was_created'),
          type: 'error'
        });
        return false;
      }

      if (formHasShiftsWithoutHours(calendarData)) {
        trackCalendarCreationWarnings('some_shift_is_not_defined');
        openNotification({
          title: t('calendars_form.title'),
          description: t('calendars_form.review_config'),
          type: 'error'
        });
        return false;
      }

      if (workdayHasMismatchingHours({ ...calendarData, hoursPerDay })) {
        trackCalendarCreationWarnings('mismatching_hours');
        setShowShiftWarning(true);
        return;
      }
      setCurrenTab(currenTab + 1);
    }

    if (currenTab === 3) {
      setLoading(true);
      const data = {
        name: calendarState.calendarForm.name,
        unique_id: new Date().getTime(),
        is_default: is_default,
        working_days: working_days,
        shift_start: `${start_ini}-${start_end}`,
        shift_end: `${end_ini}-${end_end}`,
        sectorId: projectState.sectorSelected,
        ganttId: currentGantt,
        userId: loggedUser.id
      };

      const calendarCreated = await createCalendar(data);
      if (calendarCreated.id) {
        const toAssignCalendarId = calendarCreated.id;
        const asyncMapWorkingDaySave =
          calendarState.calendarForm.workingDays.map(async (shift) => {
            const formattedIni = formatShift(shift.shift_ini);
            const formattedEnd = formatShift(shift.shift_end);
            const newWorkingDay = {
              shift_string: `${formattedIni}-${formattedEnd}`,
              correlative_id: shift.correlative_id,
              calendarId: toAssignCalendarId
            };
            await workingdayService.create(newWorkingDay);
          });
        await Promise.all(asyncMapWorkingDaySave);
        const resp = await getCalendars();
        const calendarsActive = resp.calendar.filter(
          (e) => e.sectorId === projectState.sectorSelected
        );
        dispatch(calendarActions.setCalendarsbySector(calendarsActive));

        if (calendarState.calendarForm.exceptions) {
          await saveExceptions(calendarCreated);
        }
        openNotification(alertCalendarEditSuccess);
        setLoading(false);
        resetStateCalendar();
      }

      trackingEvent(
        'finish_calendar_creation',
        {
          ...getBasicAmplitudEventProperties(),
          edit_shift_manually: isShiftsActive
        },
        AMPLITUDE_SERVICE
      );
    }
  };

  const saveExceptions = async (calendarSelected) => {
    const asyncExceptions = calendarState.calendarForm.exceptions.map(
      async (exc) => {
        const start_ini =
          exc.hourini === 'Start' ? null : formatShift(exc.hourini);
        const start_end =
          exc.hourend === 'End' ? null : formatShift(exc.hourend);
        const data = {
          name: exc.name,
          unique_id: new Date().getTime(),
          to_date: exc.to_date,
          from_date: exc.from_date,
          calendarId: calendarSelected.id,
          workable: exc.workable,
          iterable: exc.iterable,
          shift_start: `${start_ini}-${start_end}`
        };

        if (exc.iterable) {
          data.every = exc.every;
          data.shift_start = `${isNaN(exc.hourini) ? null : formatShift(exc.hourini)}-${isNaN(exc.hourend) ? null : formatShift(exc.hourend)}`;
        }

        const resException = await createExceptionDay(data);
        if (resException) {
          const asyncExceptionWorkingday = exc.workingDays.map(
            async (shift) => {
              const toAssignExceptionId = resException.id;
              const formattedIni = formatShift(shift.shift_ini);
              const formattedEnd = formatShift(shift.shift_end);
              const newWorkingDay = {
                shift_string: `${formattedIni}-${formattedEnd}`,
                correlative_id: shift.correlative_id,
                calendarexceptiondayId: toAssignExceptionId
              };

              await workingdayService.create(newWorkingDay);
            }
          );

          await Promise.all(asyncExceptionWorkingday);
        }
      }
    );

    await Promise.all(asyncExceptions);
  };

  const handleClose = () => {
    trackingEvent(
      'cancel_calendar_creation',
      {
        ...getBasicAmplitudEventProperties()
      },
      AMPLITUDE_SERVICE
    );
    setCurrenTab(1);
    setVisibleForm(false);
    resetStateCalendar();
    dispatch(calendarActions.setCalendarForm(null));
  };

  const handleBack = () => {
    setCurrenTab(currenTab - 1);
  };

  /** render */
  return (
    <Modal
      closable={false}
      className="frmCalendar"
      title=""
      visible={visibleForm}
      centered
      keyboard={false}
      maskClosable={false}
      onCancel={handleClose}
      width={1200}
      bodyStyle={{ height: '55vh', overflow: 'auto' }}
      footer={
        /** submit button in footer */
        <div className="footerBtns" key="btns">
          <Button
            loading={loading}
            onClick={handleContinue}
            className="btnSubmit">
            {currenTab != 3
              ? t('calendars_form.continue')
              : t('calendars_form.save')}
          </Button>
        </div>
      }>
      <div className="title-frm-add">
        <div
          onClick={handleBack}
          className={currenTab <= 1 ? 'hidden' : 'back-arrow'}>
          <LeftOutlined />
        </div>
        {t('calendars_form.create')}
        <div className="title-close" onClick={handleClose}>
          <CloseOutlined />
        </div>
      </div>

      <Row className="frm">
        <Col span={24} className="frmCol">
          <div className={currenTab === 1 ? null : 'hidden'}>
            <Input
              className="txt-name"
              type="text"
              name="cname"
              placeholder={t('calendars_form.name')}
              autoComplete="off"
              required
              value={
                calendarState.calendarForm && calendarState.calendarForm.name
              }
              onChange={(e) =>
                dispatch(
                  calendarActions.setCalendarForm({
                    ...calendarState.calendarForm,
                    name: e.target.value
                  })
                )
              }
            />
            {
              /** validate name */
              nameCalendar !== null && nameCalendar === '' ? (
                <label className="lblError">
                  {t('calendars_form.req_name')}
                </label>
              ) : null
            }
          </div>

          {/* Render Days Schedule */}
          {visibleForm ? (
            <div className={currenTab === 2 ? null : 'hidden'}>
              {showShiftWarning && (
                <AlertSystem
                  className="alert-warning-calendar"
                  message={mismatchedMessageWithLink({
                    t,
                    source: 'creation'
                  })}
                  type="warning"
                  newWarningIcon
                />
              )}
              <Col span={24}>
                <div className="title green">
                  {t('master_plan_calendars.confirure_text_workin_days')}
                </div>
              </Col>
              <FormDays
                currenTab={currenTab}
                setCurrenTab={setCurrenTab}
                add1Hour={add1Hour}
                t={t}
                defaultCalendar={defaultCalendar}
                setToggleActivation={setIsShiftsActive}
              />
            </div>
          ) : null}

          {/* Render Exceptions */}
          {visibleForm ? (
            <div className={currenTab === 3 ? null : 'hidden'}>
              <FormExceptionsDays
                currenTab={currenTab}
                setCurrenTab={setCurrenTab}
                add1Hour={add1Hour}
                t={t}
              />
            </div>
          ) : null}
        </Col>
      </Row>
    </Modal>
  );
}

export default withTranslation()(CalendarForm);
