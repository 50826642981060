/* eslint-disable prefer-const */

/** React components  */
import React, { useState, useEffect } from 'react';

/** Import elements from library Antd */
import { Select, Row, Col, Tooltip } from 'antd';

/** import common functions from utils */
import { week_days } from '../../../../utils';

/** Redux implementation */
import { useSelector, useDispatch } from 'react-redux';
import { calendarActions } from '../../../../redux/actions/calendarActions';
import { withTranslation } from 'react-i18next';
import trashWorkingIcon from '../../../../assets/img/trash-workingday.png';

import { trackingEvent } from '../../../../analytics/index';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../../analytics/utils';
import CheckboxSystem from '../../../DesignSystem/CheckboxSystem/CheckboxSystem';

import { PlusIcon, MinusIcon } from '../../../../icons';
import { Colors } from '../../../../constants/colors.constants.js';

const { Option } = Select;

function FormDays(props) {
  /** traduction */
  const { t } = props;
  /** redux */
  const dispatch = useDispatch();
  const calendarState = useSelector((state) => state.calendarState);

  /** use props */
  const {
    currentCalendar,
    setCurrentCalendar,
    add1Hour,
    calendarsSelected,
    toDropWorkingDays
  } = props;

  /** hooks */
  const [newh, setNewh] = useState([]);
  const [workingDays, setWorkingDays] = useState([]);

  useEffect(() => {
    if (calendarsSelected) {
      setWorkingDays(calendarsSelected.workingDays);
    }
  }, []);

  /** Similar to did mount */
  useEffect(() => {
    if (calendarState.calendarSelected) {
      /* const start = calendarState.calendarSelected.shift_start.split('-')
            const end = calendarState.calendarSelected.shift_end.split('-')
            setCurrentCalendar({
                ...currentCalendar,
                days: calendarState.calendarSelected.working_days.split(',').map(e => {
                    return (e === 'true')
                }),
                shiftStart_ini: parseInt(start[0]),
                shiftStart_end: parseInt(start[1]),
                shiftEnd_ini: parseInt(end[0]),
                shiftEnd_end: parseInt(end[1])
            }) */
    }
  }, [calendarState.calendarSelected]);

  useEffect(() => {
    /** generate hours array */
    let hours = [...Array(25).keys()];
    const calcH = hours.map((e) => ({
      value: e,
      label: e < 10 ? `0${e}:00` : `${e}:00`
    }));
    setNewh(calcH);
  }, []);

  /** logic component */

  /** handle checked of workable days */
  const handleChangeCheckbox = (index) => {
    const currentState = calendarState.calendarForm;
    const daysTmp = currentState.days;

    /** set to false every hoour if checkbox checked is false */
    const newCheck = !daysTmp[index];
    if (newCheck === false) {
      workingDays.map((wd) => {
        wd.shift_ini[index] = false;
        wd.shift_end[index] = false;
      });
      setWorkingDays([...workingDays]);
    }

    daysTmp[index] = newCheck;
    const newState = {
      ...currentState,
      days: [...daysTmp]
    };
    const handleWorkingDay = daysTmp[index]
      ? 'working_day_selection'
      : 'working_day_deselection';
    trackingEvent(
      handleWorkingDay,
      {
        ...getBasicAmplitudEventProperties(),
        event_source: calendarState.calendarSelected
          ? 'calendar_edition'
          : 'calendar_creation',
        calendar_name:
          calendarState.calendarSelected?.name ??
          calendarState.calendarForm.name,
        calendar_id: calendarState.calendarSelected?.id ?? '',
        day_name: index
      },
      AMPLITUDE_SERVICE
    );

    dispatch(calendarActions.setCalendarForm(newState));
  };

  /** this function reply shift of index selected */
  const handleReplyShift = (shift, dayIndex) => {
    const toCopyIni = shift.shift_ini[dayIndex];
    const toCopyEnd = shift.shift_end[dayIndex];
    const currentState = calendarState.calendarForm;
    const daysTmp = currentState.days;

    shift.shift_ini.map((day, index) => {
      if (daysTmp[index]) {
        shift.shift_ini[index] = toCopyIni;
      }
    });
    shift.shift_end.map((day, index) => {
      if (daysTmp[index]) {
        shift.shift_end[index] = toCopyEnd;
      }
    });
    setWorkingDays([...workingDays]);
    dispatch(
      calendarActions.setCalendarForm({
        ...calendarState.calendarForm,
        workingDays: workingDays
      })
    );
  };

  const addWorkingDay = () => {
    setWorkingDays([
      ...workingDays,
      {
        shift_ini: [false, false, false, false, false, false, false],
        shift_end: [false, false, false, false, false, false, false],
        correlative_id: workingDays.length
      }
    ]);
    dispatch(
      calendarActions.setCalendarForm({
        ...calendarState.calendarForm,
        workingDays: [
          ...workingDays,
          {
            shift_ini: [false, false, false, false, false, false, false],
            shift_end: [false, false, false, false, false, false, false],
            correlative_id: workingDays.length
          }
        ]
      })
    );

    trackingEvent(
      'working_shift_ creation',
      {
        ...getBasicAmplitudEventProperties(),
        event_source: calendarState.calendarSelected
          ? 'calendar_edition'
          : 'calendar_creation',
        calendar_name:
          calendarState.calendarSelected?.name ??
          calendarState.calendarForm.name,
        calendar_id: calendarState.calendarSelected?.id ?? ''
      },
      AMPLITUDE_SERVICE
    );
  };

  const removeWorkingDay = () => {
    if (workingDays.length > 0) {
      let workingDaysCopy = [...workingDays];
      let discard = workingDaysCopy.pop();
      if (discard.id) {
        toDropWorkingDays.push(discard);
      }
      setWorkingDays([...workingDaysCopy]);
      dispatch(
        calendarActions.setCalendarForm({
          ...calendarState.calendarForm,
          workingDays: [...workingDaysCopy]
        })
      );
    }
    trackingEvent(
      'working_shift_deletion',
      {
        ...getBasicAmplitudEventProperties(),
        event_source: calendarState.calendarSelected
          ? 'calendar_edition'
          : 'calendar_creation',
        calendar_name:
          calendarState.calendarSelected?.name ??
          calendarState.calendarForm.name,
        calendar_id: calendarState.calendarSelected?.id ?? '-'
      },
      AMPLITUDE_SERVICE
    );
  };

  const renderHoursForWorking = (shift, index, selectType) => {
    let conditionHour = -1;
    if (selectType == 'start') {
      if (shift.correlative_id) {
        conditionHour = add1Hour(
          workingDays[shift.correlative_id - 1].shift_end[index],
          0
        );
        shift.conditionHourIni = conditionHour;
      }
    } else if (selectType == 'end') {
      conditionHour = add1Hour(shift.shift_ini[index], 1);
      shift.conditionHourEnd = conditionHour;
    }

    let options = newh
      .sort() /** generate Select Hours */
      .map((hour, index) => {
        let disabledOption = false;
        if (index < conditionHour) {
          disabledOption = true;
        }
        return (
          <Option disabled={disabledOption} key={index} value={hour.value}>
            {hour.label}
          </Option>
        );
      });
    return options;
  };

  const deleteShift = (shift, index) => {
    const toDelete = workingDays[index];
    if (toDropWorkingDays) {
      toDropWorkingDays.push(toDelete);
    }
    const arrayWithoutElementToDelete = workingDays.filter(
      (el) => toDelete != el
    );
    arrayWithoutElementToDelete.map((el, index) => {
      el.correlative_id = index;
    });

    setWorkingDays([...arrayWithoutElementToDelete]);
  };

  const handleChangeHoursWorkingDays = (
    value,
    shiftType,
    dayIndex,
    workingDayIndex
  ) => {
    if (shiftType == 'start') {
      workingDays[workingDayIndex].shift_ini[dayIndex] = value;
    } else if (shiftType == 'end') {
      workingDays[workingDayIndex].shift_end[dayIndex] = value;
    }
    const reference = workingDays[workingDayIndex];
    const totalWorkingDays = workingDays.length;

    for (let i = reference.correlative_id; i < totalWorkingDays; i++) {
      if (i == reference.correlative_id) {
        if (shiftType == 'start') {
          workingDays[i].shift_end[dayIndex] = false;
        }
      } else {
        workingDays[i].shift_ini[dayIndex] = false;
        workingDays[i].shift_end[dayIndex] = false;
      }
    }

    dispatch(
      calendarActions.setCalendarForm({
        ...calendarState.calendarForm,
        workingDays: workingDays
      })
    );
    setWorkingDays([...workingDays]);
  };

  /** variables for render */

  /** render */
  return calendarState.calendarForm ? (
    <Row className="frm frm-calendar">
      <Col className="checkDays checkboxsDays">
        <div className="div-day header">
          <div className="day-label">&nbsp;</div>
          <div className="day-check">
            <label>{t('master_plan_calendars.workeable')}</label>
          </div>
          {workingDays.map((shift, index) => (
            <div className="day-shift header-col" key={index}>
              {shift.correlative_id + 1}°{' '}
              {t('master_plan_calendars.journal_shift')}
              <span className="on-hover-delete-working-day">
                <span
                  style={{ marginLeft: 5 }}
                  onClick={() => deleteShift(shift, index)}>
                  <img
                    className="delete-icon"
                    src={trashWorkingIcon}
                    width={10}
                  />
                </span>
              </span>
            </div>
          ))}
          <div className="add-working-day-btn" onClick={addWorkingDay}>
            <PlusIcon color={Colors.DARK_MINT} />
            {t('master_plan_calendars.journal_shift')}
          </div>
          {workingDays && workingDays.length ? (
            <div className="add-working-day-btn" onClick={removeWorkingDay}>
              <MinusIcon color={Colors.DARK_MINT} />
              {t('master_plan_calendars.journal_shift')}
            </div>
          ) : null}
        </div>
        {week_days.map((d, index) => (
          <div className="div-day" key={index}>
            <div
              className="day-label"
              onClick={(e) => handleChangeCheckbox(index)}>
              {t(`weeks.${d.trad}`)}
            </div>

            {/* check days */}
            <div className="day-check">
              <CheckboxSystem
                checked={
                  calendarState.calendarForm.days &&
                  calendarState.calendarForm.days[index]
                }
                onChange={(e) => handleChangeCheckbox(index)}
              />
            </div>

            {/** Working days iteration */}
            {workingDays.map((shift, key) => (
              <div
                key={key}
                className={`day-shift ${calendarState.calendarForm.days && calendarState.calendarForm.days[index] ? null : 'disabled'}`}>
                {/** Start hour select */}
                <Select
                  dropdownClassName="dropdown-hours-style"
                  disabled={
                    calendarState.calendarForm.days &&
                    !calendarState.calendarForm.days[index]
                  }
                  bordered={false}
                  value={
                    shift.shift_ini[index] === false
                      ? t('calendars_form.start')
                      : parseInt(shift.shift_ini[index])
                  }
                  className="shiftSelect"
                  onChange={(e) =>
                    handleChangeHoursWorkingDays(e, 'start', index, key)
                  }>
                  {renderHoursForWorking(shift, index, 'start')}
                  <Option key={24} value={false}>
                    {t('calendars_form.start')}
                  </Option>
                </Select>

                {/** End hour select */}
                <Select
                  dropdownClassName="dropdown-hours-style"
                  disabled={
                    calendarState.calendarForm.days &&
                    !calendarState.calendarForm.days[index]
                  }
                  bordered={false}
                  value={
                    shift.shift_end[index] === false
                      ? t('calendars_form.end')
                      : parseInt(shift.shift_end[index])
                  }
                  className="shiftSelect"
                  onChange={(e) =>
                    handleChangeHoursWorkingDays(e, 'end', index, key)
                  }>
                  {renderHoursForWorking(shift, index, 'end')}
                  <Option key={24} value={false}>
                    {t('calendars_form.end')}
                  </Option>
                </Select>

                <div className="reply-shift-div">
                  <Tooltip
                    title={t('calendars_form.apply_to_all')}
                    onClick={() => handleReplyShift(shift, index)}>
                    <i className="fas fa-tasks reply-shift"></i>
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
        ))}
      </Col>
    </Row>
  ) : (
    'loading'
  );
}

export default withTranslation()(FormDays);
