import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { useCalendarOptions } from './useCalendarOptions';
import PopoverUniselectSystem from '../../../DesignSystem/PopoverUniselectSystem';
import { ThreeDotsHorizontal } from '../../../../icons';
import './CalendarOptions.scss';

const CalendarCloneModal = React.lazy(
  () => import('./CalendarCloneModal/CalendarCloneModal')
);

const CalendarImportHolidaysModal = React.lazy(
  () => import('./CalendarImportHolidaysModal/CalendarImportHolidaysModal')
);

const CalendarDeleteConflictModal = React.lazy(
  () => import('./CalendarDeleteConflictModal/CalendarDeleteConflictModal')
);

const CalendarDeleteConfirmationModal = React.lazy(
  () =>
    import('./CalendarDeleteConfirmationModal/CalendarDeleteConfirmationModal')
);

const WIDTH_POPOVER = 180;

const CalendarOptions = ({
  calendarId,
  calendarName,
  handleClickItem,
  isDefault,
  setCalendarsList
}) => {
  const {
    optionsPopoverRef,
    optionsPopoverItems,
    isCalendarCloneModalVisible,
    setIsCalendarCloneModalVisible,
    isCalendarImportHolidaysVisible,
    setIsCalendarImportHolidaysVisible,
    isCalendarDeleteConflictVisible,
    setIsCalendarDeleteConflictVisible,
    isCalendarDeleteConfirmationVisible,
    setIsCalendarDeleteConfirmationVisible
  } = useCalendarOptions(calendarId, isDefault);

  return (
    <>
      <PopoverUniselectSystem
        itemReference={optionsPopoverRef}
        width={WIDTH_POPOVER}
        trigger="click"
        items={optionsPopoverItems}
        placement="right"
        withSearch={false}>
        <div className="calendar-options__popover">
          <ThreeDotsHorizontal />
        </div>
      </PopoverUniselectSystem>
      <Suspense fallback={<div />}>
        <CalendarCloneModal
          calendarId={calendarId}
          calendarName={calendarName}
          isCalendarCloneVisible={isCalendarCloneModalVisible}
          setIsCalendarCloneVisible={setIsCalendarCloneModalVisible}
        />
        <CalendarImportHolidaysModal
          calendarId={calendarId}
          isCalendarImportHolidaysVisible={isCalendarImportHolidaysVisible}
          setIsCalendarImportHolidaysVisible={
            setIsCalendarImportHolidaysVisible
          }
          handleClickItem={handleClickItem}
        />
        <CalendarDeleteConflictModal
          calendarId={calendarId}
          calendarName={calendarName}
          isCalendarDeleteConflictVisible={isCalendarDeleteConflictVisible}
          setIsCalendarDeleteConflictVisible={
            setIsCalendarDeleteConflictVisible
          }
        />
        <CalendarDeleteConfirmationModal
          calendarId={calendarId}
          calendarName={calendarName}
          isCalendarDeleteConfirmationVisible={
            isCalendarDeleteConfirmationVisible
          }
          setIsCalendarDeleteConfirmationVisible={
            setIsCalendarDeleteConfirmationVisible
          }
          setCalendarsList={setCalendarsList}
        />
      </Suspense>
    </>
  );
};

CalendarOptions.propTypes = {
  calendarId: PropTypes.number.isRequired,
  calendarName: PropTypes.string.isRequired,
  handleClickItem: PropTypes.func.isRequired,
  isDefault: PropTypes.bool.isRequired,
  setCalendarsList: PropTypes.func.isRequired
};

export default CalendarOptions;
