import React from 'react';
import { Modal } from 'antd';
import { CloseNotificationIcon } from '../../../icons';
import { Colors } from '../../../constants/colors.constants.js';

const ModalSystem = ({
  children,
  theme = 'default',
  visible,
  setVisible,
  showTitle = true,
  title,
  showIconTitle,
  iconTitle,
  closable = true,
  iconClose = <CloseNotificationIcon color={Colors.WHITE} />,
  onCancelFunction,
  ...props
}) => {
  const handleCloseModal = () => {
    onCancelFunction && onCancelFunction();
    setVisible(false);
  };

  const closeContent = () => (
    <div className="close__icon" onClick={() => handleCloseModal()}>
      {iconClose}
    </div>
  );

  return (
    <Modal
      {...props}
      visible={visible}
      className={`modal-design-system theme--${theme}`}
      closable={false}
      title={null}
      footer={null}>
      {showTitle && title && (
        <div className="modal-design-system__title">
          <div className="title__content">
            {showIconTitle && iconTitle && (
              <div className="content__icon">{iconTitle}</div>
            )}
            <h2>{title}</h2>
          </div>
          {closable && <div className="title__close">{closeContent()}</div>}
        </div>
      )}

      {!showTitle && closable && (
        <div className="modal-design-system__close">{closeContent()}</div>
      )}

      <div
        className={`modal-design-system__content ${!showTitle && closable ? 'padding' : ''}`}>
        {children}
      </div>
    </Modal>
  );
};

export default ModalSystem;
