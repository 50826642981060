import { Trans } from 'react-i18next';
import React from 'react';

export const mismatchedMessageWithLink = ({ t, source = 'creation' }) => {
  const articleLinks = {
    es: 'https://knowledgebase.outbuild.com/es/knowledge/configuración-de-horas-de-trabajo-del-proyecto',
    en: 'https://knowledgebase.outbuild.com/en/knowledge/project-working-hours-setup'
  };
  const userLanguage = t('lang') === 'es' ? 'es' : 'en';
  const key =
    source === 'creation' ? 'create_calendar_mismatch' : 'edit_mismatch_hours';
  return (
    <Trans
      i18nKey={`master_plan_calendars.${key}`}
      components={{
        a: (
          <a
            href={articleLinks[userLanguage]}
            target="_blank"
            rel="noreferrer"
          />
        )
      }}
    />
  );
};
