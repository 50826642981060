import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Popover, Tooltip } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import SelectSearch from 'react-select-search';
import cn from 'classnames';
import { useQuery } from 'react-query';

import { getAllAssignableUsersSelector } from '../../redux/slices/projectSlice';
import { taskUpdateRequested } from '../../redux/slices/taskSlice';
import { activityUpdateRequested } from '../../redux/slices/activitySlice';
import { userService } from '../../services/user.service';
import noAvatar from '../../assets/img/fake_user.png';
import styles from './AssigneesPicker.module.scss';

const PROJECT_USERS_KEY = 'projectUsers';

export const AssigneesPicker = ({
  allAssignableUsers,
  selectedUsers,
  entity,
  onUpdated,
  displayNameIfSingleUser = false,
  editable = true,
  t,
  ...actions
}) => {
  const currentProject = JSON.parse(sessionStorage.getItem('currentProject'));

  const { data: projectUsers = [], isLoading: isLoadingUsers } = useQuery({
    queryKey: [PROJECT_USERS_KEY, currentProject?.id],
    queryFn: async () => {
      const response = await userService.getUsersProjectSettings(
        currentProject?.id
      );
      return response.users || [];
    },
    refetchOnWindowFocus: false,
    enabled: Boolean(currentProject?.id)
  });

  if (!entity) return null;

  // If it has an activityId, then it's a task
  const isTask = Boolean(entity.activityId);
  const { activityUpdateRequested, taskUpdateRequested } = actions;

  const usersResponsible = [];
  const selected = [];
  const dictionary = {};

  projectUsers.forEach((user) => {
    if (user && user.email) {
      dictionary[user.email] = user;
      if (user.is_active) {
        usersResponsible.push({
          name: [user.name, user.lastname].join(' '),
          value: user.email,
          thumbnail: user.image,
          ...user
        });
      }
    }
  });

  selectedUsers.forEach((res) => {
    if (res && res.email && dictionary[res.email]) {
      selected.push(res.email);
    }
  });

  const avatars = (
    <div
      className={cn(styles['assignees-picker'], {
        [styles['assignees-picker--editable']]: editable
      })}>
      <span className={styles['assignees-picker__avatars-list']}>
        {isLoadingUsers ? (
          <span>{t('loading')}</span>
        ) : selectedUsers?.length ? (
          selectedUsers.map((user, i) => (
            <Tooltip title={user.name} key={i}>
              <img src={user.thumbnail || noAvatar} />
              {selectedUsers.length === 1 && displayNameIfSingleUser ? (
                <span className={styles['assignees-picker__name']}>
                  {user.name}
                </span>
              ) : null}
            </Tooltip>
          ))
        ) : editable ? (
          <UserAddOutlined title={t('select_responsable_tooltip')} />
        ) : (
          '-'
        )}
      </span>
    </div>
  );

  const renderOption = (props, option, _, className) => {
    return (
      <button
        {...props}
        title={option.email}
        className={cn(styles['assignees-picker__popover-option'], className)}>
        <img
          className={styles['assignees-picker__popover-option-avatar']}
          src={option.thumbnail || noAvatar}
        />
        <span
          className={styles['assignees-picker__popover-option-name']}
          title={option.name}>
          {option.name} {option.lastname || ''}
        </span>
      </button>
    );
  };

  /**
   * This function transforms the data structure for responsible, to use it in the gantt
   * @param {*} assignees Array of users
   * @returns Array transformed according to gantt data
   */
  const getAssigneesWithformatGantt = (assignees) => {
    const ret = assignees.map((el) => {
      return {
        id: el.id,
        email: el.email,
        role: el.role,
        name: el.firstName,
        lastname: el.lastName,
        is_active: el.isActive,
        country: null,
        position: el.position,
        image: el.thumbnail,
        dni: null,
        companyId: el.companyId
      };
    });
    return ret;
  };

  return editable ? (
    <Popover
      content={
        <SelectSearch
          className="select-search select-search--multiple"
          value={selected}
          options={usersResponsible}
          renderOption={renderOption}
          onChange={(selectedEmails) => {
            const assignees = selectedEmails
              .map((email) => dictionary[email])
              .filter(Boolean);

            if (isTask) {
              taskUpdateRequested({
                task: { ...entity, responsables: assignees },
                onUpdated
              });
            } else {
              onUpdated({ ...entity, responsables: assignees });
              /** get task from gantt */
              const taskGantt = window.to_use_react_gantt.getTask(
                entity.unique_id
              );
              /** transform assignees array */
              const assigneesWithformnatGantt =
                getAssigneesWithformatGantt(assignees);
              /** assign responsables to task original */
              taskGantt.responsables = assigneesWithformnatGantt;
              /** update gantt */
              window.to_use_react_gantt.updateTask(taskGantt.id);
              window.to_use_react_gantt.render();
            }
          }}
          multiple
          search
          placeholder={t('search_responsable_placeholder')}
        />
      }
      trigger="click">
      {avatars}
    </Popover>
  ) : (
    avatars
  );
};

const actionCreators = {
  taskUpdateRequested,
  activityUpdateRequested
};

const mapStateToProps = (state) => ({
  allAssignableUsers: getAllAssignableUsersSelector(state)
});

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(AssigneesPicker));
