import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import {
  InformationCircleOutlineIcon,
  SuccessSolidIcon,
  WarningOutlineIcon,
  ErrorOutlineIcon,
  WarningNewIcon
} from '../../../icons';

const INFO_TYPE = 'info';
const SUCCESS_TYPE = 'success';
const WARNING_TYPE = 'warning';
const ERROR_TYPE = 'error';
const DEFAULT_SIZE = 24;

const AlertSystem = ({ newWarningIcon, ...props }) => {
  const { type } = props;

  const getIconType = (type = INFO_TYPE) => {
    const listTypes = {
      [INFO_TYPE]: (
        <InformationCircleOutlineIcon color="#498E98" size={DEFAULT_SIZE} />
      ),
      [SUCCESS_TYPE]: <SuccessSolidIcon color="#53C255" size={DEFAULT_SIZE} />,
      [WARNING_TYPE]: !newWarningIcon ? (
        <WarningOutlineIcon color="#FDD551" size={DEFAULT_SIZE} />
      ) : (
        <WarningNewIcon color="#FDD551" size={DEFAULT_SIZE} />
      ),
      [ERROR_TYPE]: <ErrorOutlineIcon color="#E50101" size={DEFAULT_SIZE} />
    };

    return listTypes[type];
  };

  return (
    <Alert
      className="alert-design-system"
      icon={getIconType(type)}
      showIcon={true}
      {...props}
    />
  );
};

AlertSystem.propTypes = {
  newWarningIcon: PropTypes.bool,
  type: PropTypes.oneOf([INFO_TYPE, SUCCESS_TYPE, WARNING_TYPE, ERROR_TYPE]),
  message: PropTypes.node.isRequired,
  description: PropTypes.node,
  showIcon: PropTypes.bool,
  className: PropTypes.string
};

AlertSystem.defaultProps = {
  newWarningIcon: false,
  type: INFO_TYPE,
  showIcon: true
};

export default AlertSystem;
