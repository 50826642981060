/** React components  */
import React, { useState, useEffect, useRef } from 'react';

/** Build components from antd css framework */
import {
  Tooltip,
  Button,
  Drawer,
  Popover,
  Input,
  Progress,
  Popconfirm,
  Icon,
  DatePicker,
  Modal,
  InputNumber
} from 'antd';
import {
  CheckCircleOutlined,
  UnorderedListOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';

/** import common functions from utils */
import {
  taskStatus,
  getActivitiesforWeeklyPlan,
  openNotification
} from '../../utils';

/** import utils library's */
import moment from 'moment';
import { capitalize } from 'lodash';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import EditableInput from '../EditableInput';

/** import select user component */
import SelectSearch from 'react-select-search';

/** import progress bar component */
import ProgressBarCard from '../ProgressBarCard';

import {
  getEndDateByGantt,
  renderFriend,
  calculateProgress,
  calculatePonderators,
  calculateLeanStatus,
  formatMoney,
  launchInlineEditorEvent
} from '../../utils/lookahead-common';

import { socket } from '../../services/socket.service';

/** Services */
import {
  cncService,
  constraintService,
  constraintScheduleService,
  weekCommitmentService,
  taskService,
  activityService,
  notificationService
} from '../../services';

import { constraintActions } from '../../redux/actions/constraintActions';
import { userActions } from '../../redux/actions/userActions';

/** Redux */
import { useSelector } from 'react-redux';

/** JSON with table distribution */
import { tableMetadata as tableMetadataConstraints } from '../../views/lookahead/constraints/table.layout';
import { tableMetadata as tableMetadataCncs } from '../../views/weeklyPlan/cncs/table.layout';
import { tableMetadata as tableConfig } from '../../views/weeklyPlan/planning/table.layout';

/** SVG image */
import selectResponsablesIcon from '../../assets/img/select_responsables.png';
import fakeAvatar from '../../assets/img/fake_user.png';
import IconComponent from '../../components/Projects/IconSvg';
import { helmet } from '../../utils';
import {
  defineRestrictedTaskByChilds,
  findDeepGetTask,
  updateAsyncTaskGanttV2
} from '../GanttVisualization/GanttVisualization.helper';
import MoneySymbolString from '../../components/MoneySymbolString';
import { getSignedUser } from '../../utils/userUtils';
import { TimerManagerSingleton } from '../../utils/timerManager';

/** const for max width task name at card */
const TASK_COLUMN_NAME_WIDTH = 2000;

export default function CardTask(props) {
  const { confirm } = Modal;
  const dispatch = useDispatch();

  /** props */
  const {
    currentWeekWithPday,
    currentTask,
    currentActivity,
    gantt,
    dateRange,
    belongsToRange,
    subContracts,
    lastLevelActivities,
    onCloseCard,
    cardState,
    activities,
    handshake,
    t,
    toSelectResponsables,
    updateAsyncTask,
    handlePopVisibility,
    popsVisibility,
    handlePopVisibilityCloseAll,
    permission,
    setVisibleFormSubcontract,
    updateAsyncActivity,
    updateRender,
    excludeAfterAction = true,
    deleteSubcontract,
    currentViewLookahead = null
  } = props;

  /** Project state from redux */ /** Project state from redux */
  const projectState = useSelector((state) => state.projectState);
  const sectorDateFormat = projectState.allSectors.find(
    (e) => e.id === projectState.sectorSelected
  );
  const formatDate =
    sectorDateFormat && sectorDateFormat.dateFormat
      ? sectorDateFormat.dateFormat
      : null;

  /** hooks */
  const [tableConfigWeekly, setTableConfigWeekly] = useState([]);
  const [tableConfigConstraints, setTableConfigConstraints] = useState([]);
  const [tableConfigCncs, setTableConfigCncs] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [popPriorityVisible, setPopPriorityVisible] = useState(false);
  const [popSubcontractVisible, setPopSubcontractVisible] = useState(false);
  const [popsVisibilityConstraints, setPopsVisibilityConstraints] = useState(
    {}
  );
  const [editedInput, setEditedInput] = useState(null);
  const onlyRead = 'V';
  const user = getSignedUser();
  const [commitmentPercentaje, setCommitmentPercentaje] = useState(null);
  const [cncsTask, setCncsTask] = useState([]);
  const [logsTask, setLogsTask] = useState(null);
  const [logToRender, setLogToRender] = useState(null);
  const [progressForBar, setProgressForBar] = useState({ value: 0 });
  const [renderPgBar, setRenderPgBar] = useState(null);
  const [renderPgInp, setRenderPgInp] = useState(null);
  const [reloadLogs, setReloadLogs] = useState(false);
  const [reloadAvailable, setReloadAvailable] = useState(false);
  const [costAvailableState, setCostAvailableState] = useState(0);
  const timerManager = TimerManagerSingleton.getInstance();

  /** variables */
  let progressColor;
  let subContractObject = null;
  let colorObject = null;
  let priorityArr = null;
  let statusArr = null;
  let iconToShow = null;
  let routeString = null;
  let uniqueId = null;
  let columnName = null;
  let columnNameLean = null;
  let columnNameResponsable = null;
  let columnNameProgress = null;
  let columnNameDuration = null;
  const ammountCostAssignedToActivity = useRef(null);

  let columnCostTotal = null;
  let columnCncStatus = null;
  let columnCncName = null;

  const hasEditPermission = (permission, userId, responsableId) =>
    permission === 'ACP' && userId !== responsableId;

  useEffect(() => {
    if (currentTask) {
      if (currentTask.lean_status === 'Committed') {
        getTaskCommitment();
      }
      if (!currentTask.activityId) {
        currentTask.activityId = currentTask?.activityReference?.proplannerId;
      }
      getCncs();
      getLog();
      setProgressForBar({ value: currentTask.progress });
      setCurrentTab(0);
    }
  }, [currentTask]);

  useEffect(() => {
    if (currentTask) {
      const reloadLogsCallback = () => {
        getLog();
      };
      timerManager.registerAutoTimeout(
        reloadLogsCallback,
        800,
        'cardTaskReloadLogsTimer'
      );
    }
  }, [reloadLogs]);

  /** reload available */
  useEffect(() => {
    if (currentTask) {
      const reloadAvailableCallback = () => {
        if (currentActivity !== null) {
          getAvailable();
        }
      };
      timerManager.registerAutoTimeout(
        reloadAvailableCallback,
        800,
        'cardTaskReloadAvailableTimer'
      );
    }
  }, [reloadAvailable, currentTask]);

  /** symbol currency by default  */
  const currency_symbol = MoneySymbolString() + ' ';

  const getAvailable = async () => {
    if (currentActivity === null) {
      return false;
    }

    const objGetLookAhead = {
      sector_id: projectState.sectorSelected,
      start: dateRange.start,
      end: dateRange.end,
      ignore_dates: true
    };
    const lastLevelActivitiesCard =
      await activityService.getLookahead(objGetLookAhead);
    /** get log by task */
    let costAvailable = 0;

    const activityLookahead = lastLevelActivitiesCard.lookahead.find(
      (el) => el.id === currentActivity.id
    );
    checkDataLookahead = activityLookahead && currentActivity.activities;
    /** cost */
    if (checkDataLookahead) {
      const costUsed = activityLookahead.tasks.reduce(function (acum, cur) {
        let newSum = acum;
        const curCost = parseFloat(cur.cost);
        newSum += parseFloat(curCost);
        return newSum;
      }, 0);
      costAvailable = parseFloat(currentActivity.cost) - parseFloat(costUsed);
      setCostAvailableState(costAvailable);
    }
  };

  const getLog = async () => {
    /** get log by task */
    const logTaskGet = await taskService.getLog(currentTask.id);
    // console.log('logTaskGet', logTaskGet)
    if (logTaskGet) {
      setLogsTask(logTaskGet.tasks_log);
      const logToRenderProcess = getLogToRender(logTaskGet.tasks_log);
      setLogToRender(logToRenderProcess);
    }
  };

  const getCncs = async () => {
    /** get cncs by sector && task */
    const listTmp = await cncService.showBySectorTask({
      sector_id: projectState.sectorSelected,
      task_id: currentTask.id
    });
    setCncsTask(listTmp);
  };

  useEffect(() => {
    if (currentTask) {
      setRenderPgBar(renderProgressBar());
      setRenderPgInp(renderProgressInput());
    }
  }, [progressForBar]);

  /** Effect to load translation to table declaration file (constraints) */
  useEffect(() => {
    const constraintsConfigCallback = () => {
      const copyOfTableData = [...tableMetadataConstraints];
      copyOfTableData.map((column) => {
        if (
          column.data_type.includes('/icon') ||
          column.data_type.includes('/string')
        ) {
          column.label = t(
            'tables.lookahead.constraints.' + column.name + '.label'
          );
          column.from_values.map((option) => {
            option.label = t(
              'tables.lookahead.constraints.' +
                column.name +
                '.options.' +
                option.value
            );
          });
        } else {
          column.label = t('tables.lookahead.constraints.' + column.name);
        }
      });
      setTableConfigConstraints(copyOfTableData);
    };
    timerManager.registerAutoTimeout(
      constraintsConfigCallback,
      500,
      'cardTaskConstraintsConfigTimer'
    );
  }, []);

  useEffect(() => {
    const cncsConfigCallback = () => {
      const copyOfTableData = [...tableMetadataCncs];
      copyOfTableData.map((column) => {
        if (
          column.data_type.includes('/icon') ||
          column.data_type.includes('/string')
        ) {
          column.label = t('tables.weeklyplan.cncs.' + column.name + '.label');
          column.from_values.map((option) => {
            option.label = t(
              'tables.weeklyplan.cncs.' +
                column.name +
                '.options.' +
                option.value
            );
          });
        } else {
          column.label = t('tables.weeklyplan.cncs.' + column.name);
        }
      });
      setTableConfigCncs(copyOfTableData);
    };
    timerManager.registerAutoTimeout(
      cncsConfigCallback,
      500,
      'cardTaskCncsConfigTimer'
    );
  }, []);

  useEffect(() => {
    const weeklyConfigCallback = () => {
      const copyOfTableData = [...tableConfig];
      copyOfTableData.map((column) => {
        if (
          column.data_type.includes('/icon') ||
          column.data_type.includes('/string')
        ) {
          column.label = t('tables.weeklyplan.plan.' + column.name + '.label');
          column.from_values.map((option) => {
            option.label = t(
              'tables.weeklyplan.plan.' +
                column.name +
                '.options.' +
                option.value
            );
          });
        } else {
          column.label = t('tables.weeklyplan.plan.' + column.name);
        }
      });
      setTableConfigWeekly(copyOfTableData);
    };
    timerManager.registerAutoTimeout(
      weeklyConfigCallback,
      500,
      'cardTaskWeeklyConfigTimer'
    );
  }, []);

  let friends;
  let selected;
  let dictionary;

  let colCostTotal;

  let buttonStatusLean = null;
  let contentLean = null;
  let uniqueIdTask = null;
  let blockedProgress = false;
  let parentTask = null;

  let checkDataLookahead = false;

  const showElement = true;
  const disabled = false;

  const defineIcon = (option) => {
    return <img src={option.icon} width={12} />;
  };

  const setTab = (tab) => {
    setCurrentTab(tab);
  };

  const updateAsyncConstraint = (constraint) => {
    constraintService.update(constraint);
    socket.emit('constraint_update', {
      sector: projectState.sectorSelected,
      constraint: constraint
    });
  };

  const updateAsyncCnc = (cnc) => {
    cncService.update(cnc);
    socket.emit('cnc_update', {
      sector: projectState.sectorSelected,
      cnc: cnc
    });
    // console.log('should implement socket for cnc')
  };

  const getTaskCommitment = async () => {
    const data = {
      sector_id: projectState.sectorSelected,
      week: currentWeekWithPday,
      task_id: currentTask.id
    };
    const objCommitment = await weekCommitmentService.getByWeekly(data);
    const weekObject = objCommitment ? objCommitment.weekcommitments : null;
    if (weekObject && weekObject.taskcommitments) {
      setCommitmentPercentaje(
        weekObject.taskcommitments[0].commitment_percentaje
      );
    }
  };

  const searchActivityTaskSameConstraint = (
    tasks,
    currentConstraint,
    option,
    uniqueId,
    cb
  ) => {
    const navigateIntoTask = (task) => {
      if (task.children.length) {
        searchActivityTaskSameConstraint(
          task.children,
          currentConstraint,
          option,
          uniqueId,
          cb
        );
      }

      task.constraints.forEach((constraint) => {
        if (constraint.id === currentConstraint.id) {
          cb(constraint, option, uniqueId);
        }
      });
    };

    tasks.forEach(navigateIntoTask);
  };

  /**
   * this function updates the tasks belonging to the constraint
   * @param {*} constraint constraint to update
   */
  const updateTasksOfConstraint = async (constraint) => {
    const constraintBd = await constraintService.getByConstraintThrough(
      constraint.id
    );
    /** update tasks of constraints */
    if (constraint.tasks && constraint.tasks.length) {
      constraint.tasks.map((el) => {
        const ganttRef = gantt.getTask(el.id);
        const doesExistAtReference = findDeepGetTask(activities, 'id', el.id);
        if (doesExistAtReference) {
          const isTaskRestricted = defineRestrictedTaskByChilds(
            ganttRef,
            parentTask,
            () => {}
          );
          if (isTaskRestricted || ganttRef?.is_restricted) {
            ganttRef.restricted = true;
          } else {
            ganttRef.restricted = false;
          }
          gantt.updateTask(ganttRef.id);
        }
      });
    } else {
      const tasksConstraint = constraintBd.constraints.tasks;
      if (tasksConstraint && tasksConstraint.length) {
        tasksConstraint.map((el) => {
          const ganttRef = gantt.getTask(el.id);
          const doesExistAtReference = findDeepGetTask(activities, 'id', el.id);
          if (doesExistAtReference) {
            const isTaskRestricted = defineRestrictedTaskByChilds(
              ganttRef,
              parentTask,
              () => {}
            );
            if (isTaskRestricted || ganttRef?.is_restricted) {
              ganttRef.restricted = true;
            } else {
              ganttRef.restricted = false;
            }
            gantt.updateTask(ganttRef.id);
          }
        });
      }
    }
  };

  const updateConstraint = async (constraint, option, uniqueId) => {
    const user = getSignedUser();
    constraint.status = option.value;
    if (option.value === 'released') {
      constraint.release_date = moment();
      constraint.release_user = user.id;
    }
    if (option.value === 'notreleased') {
      constraint.release_date = null;
      constraint.release_user = null;
    }
    updateAsyncConstraint(constraint);
    updateTasksOfConstraint(constraint);

    /** get tasks from constraint */
    gantt.optimizedRender();
    handlePopVisibility(false, uniqueId);
  };

  const changeStatusConstraint = (task, option, uniqueId) => {
    searchActivityTaskSameConstraint(
      currentActivity.tasks,
      task,
      option,
      uniqueId,
      updateConstraint
    );
    updateConstraint(task, option, uniqueId);
  };

  const createSchedule = async (dateString, constraintId) => {
    const data = {
      deadline: dateString,
      fail_comment: '',
      constraintId
    };
    return constraintScheduleService.create(data);
  };

  const commitmentDateChange = async (constraint, dateString) => {
    constraint.commitmentDate = dateString;
    let status = constraint.status;
    if (
      moment(dateString).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
    ) {
      status = 'expired';
    } else {
      status = 'notreleased';
    }
    constraint.status = status;
    /** Apply changes */
    const addSch = await createSchedule(dateString, constraint.id);
    constraint.schedules.push(addSch);
    updateAsyncConstraint(constraint);
    updateState();
  };

  /**
   * Clone deep of a new state to set and render view
   * @param {*} newState new state value (inmutability is broken)
   */
  const updateState = (newState = popsVisibilityConstraints) => {
    setPopsVisibilityConstraints((prev) => {
      prev = cloneDeep(newState);
      return prev;
    });
    dispatch(constraintActions.notifyLookaheadUpdate());
  };

  /** this function shown modal dialog to confirm action (rescheduling) */
  const showConfirm = (constraint, dateString) => {
    confirm({
      title: t('lookahead_constraints.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('lookahead_constraints.warning_schedule'),
      onOk() {
        commitmentDateChange(constraint, dateString);
      },
      onCancel() {
        updateAsyncConstraint(constraint);
        updateState();
      }
    });
  };

  /** this function update log after 1000 ms */
  const updateLogs = () => {
    updateRender();
    setReloadLogs(!reloadLogs);
  };

  const updateAvailable = () => {
    setReloadAvailable(!reloadAvailable);
  };

  const notifyMessage = (data) => {
    const alertErrorMailExists = {
      title: data.title,
      description: data.message,
      type: data.type
    };
    openNotification(alertErrorMailExists);
  };

  /**
   * this function notifies every time the progress of a task is updated.
   * The accumulated commitment is updated.
   * @param {*} task Updated Task object
   */
  const updateTask = (task) => {
    if (!task) return;
    let newCommit;
    if (task.total_quantity) {
      newCommit =
        (100 * task.quantity_parcial) / task.total_quantity + task.progress;
    } else {
      if (parseFloat(task.quantity_parcial_percentaje) > 0) {
        newCommit = task.quantity_parcial_percentaje + task.progress;
      } else {
        newCommit = task.progress;
      }
    }
    if (parseFloat(newCommit) > 100) {
      newCommit = 100;
      task.quantity_parcial = task.remaining_quantity;
    }
    task.commitment_percentaje = newCommit;
    /** bidirection real quantity */
    const actualQuanti = (task.progress / 100) * task.total_quantity;
    task.actual_quantity = actualQuanti;
    launchInlineEditorEvent(gantt)(task, 'progress', task.progress);
  };

  const renderCol = (
    onClick,
    index,
    column,
    task,
    onChangeFunction,
    editedInput = null,
    setEditedInput = null,
    label = null
  ) => {
    /** row */
    if (
      task.children &&
      task.children.length > 0 &&
      column.name !== 'ponderator'
    ) {
      return (
        <span className="vertical-center-card">
          <InputNumber
            disabled
            className="custom-input-number-planification ant-input-number-focused disabled-fixed-input"
            defaultValue={task[column.name]}
          />
        </span>
      );
    } else if (showElement && !disabled) {
      return (
        <span className="">
          <EditableInput
            t={t}
            service={updateAsyncTask}
            onEdit={setEditedInput}
            isEditing={editedInput}
            key={index}
            isFloat={!Number.isInteger(task[column.name])}
            renderEditable={(
              column,
              index,
              task,
              value,
              setValue,
              updateParentData = null,
              handleEsc = null
            ) => (
              <InputNumber
                onKeyDown={handleEsc}
                onPressEnter={() => {
                  updateParentData();
                  onChangeFunction();
                  updateLogs();
                  updateAvailable();
                }}
                isFloat={!Number.isInteger(task[column.name])}
                className="custom-input-number-planification ant-input-number-focused"
                disabled={disabled}
                min={0}
                name={column.name + task.id}
                id={column.name + task.id}
                precision={2}
                step={0.1}
                defaultValue={task[column.name]}
                onChange={(e) => setValue(e)}
                onFocus={(e) => e.target.select()}
              />
            )}
            updateState={updateState}
            index={index}
            column={column}
            round={false}
            symbol={
              ['cost', 'expected_cost'].includes(column.name)
                ? currency_symbol
                : null
            }
            task={task}
            isCard={true}
          />
        </span>
      );

      /** Ponderator with progress */
    } else {
      return (
        <span className="vertical-center">
          <Tooltip title={t('has_automatic_tooltip_ponderator')} key={index}>
            {t('lang') === 'en'
              ? parseFloat(task[column.name]).toFixed(2)
              : parseFloat(task[column.name]).toFixed(2).replace('.', ',')}
          </Tooltip>
        </span>
      );
    }
  };

  const defineRestrictedTask = (task, released) => {
    const restricted =
      task.constraints.length && task.constraints.length != released.length;
    const copyOfLeanStatus = JSON.parse(JSON.stringify(task.lean_status));

    if (restricted && task.lean_status != 'Restricted') {
      task.status_before_restricted = task.lean_status;
      task.lean_status = 'Restricted';
    } else if (!restricted && task.lean_status == 'Restricted') {
      task.lean_status = 'Can';
    }

    if (copyOfLeanStatus != task.lean_status) {
      if (!task.activityId) {
        task.activityId = task?.activityReference?.proplannerId;
      }
      if (task.activityId) {
        updateAsyncTask(task);
      }
    }
    return restricted;
  };

  const processRender = async () => {
    progressColor = '#52c41a';
    if (currentTask) {
      uniqueIdTask = currentTask.id + 'task';

      /** responsable */
      columnNameResponsable = tableConfigWeekly.find(
        (el) => el.name === 'responsables'
      );
      friends = [];
      selected = [];
      dictionary = {};

      currentTask[columnNameResponsable.name].map((res) => {
        selected.push(res[columnNameResponsable.el_to_extract_from]);
      });

      props[columnNameResponsable.from_values].map((user) => {
        if (user.is_active) {
          friends.push({
            name:
              user[columnNameResponsable.el_to_label_from[0]] +
              ' ' +
              user[columnNameResponsable.el_to_label_from[1]],
            // name: user[columnNameResponsable.el_to_extract_from],
            value: user[columnNameResponsable.el_to_extract_from],
            photo: user[columnNameResponsable.img_from] || fakeAvatar,
            object: user
          });
          dictionary[user[columnNameResponsable.el_to_extract_from]] = user;
        }
      });

      /** column task name */
      columnName = tableConfigWeekly.find((el) => el.name === 'name');

      /** subcontract */
      subContractObject =
        subContracts &&
        subContracts.find((e) => e.id === currentTask.subcontractId);
      colorObject = subContractObject ? subContractObject.color : null;

      /** priority */
      priorityArr = tableConfigWeekly.find(
        (el) => el.name === 'priority'
      ).from_values;
      uniqueId = currentTask.id + 'priority';
      iconToShow = priorityArr.filter((f) => f.value == currentTask.priority);

      /** status */
      statusArr = tableConfigConstraints.find(
        (el) => el.name === 'status'
      ).from_values;

      /** lean status */
      const released = currentTask.constraints.filter(
        (constraint) => constraint.status == 'released'
      );
      const isTaskRestricted = defineRestrictedTask(currentTask, released);
      columnNameLean = tableConfigWeekly.find(
        (el) => el.name === 'lean_status'
      );

      if (isTaskRestricted) {
        currentTask.restricted = true;
        buttonStatusLean = {
          class: 'card-restricted',
          label: t('restricted_label')
        };
      } else {
        currentTask.restricted = false;
        const leanObject = columnNameLean.from_values.find(
          (el) => el.value == currentTask[columnNameLean.name]
        );
        if (leanObject.value) {
          buttonStatusLean = {
            class: 'card-' + leanObject.value,
            label: leanObject.label
          };
        }
      }

      contentLean = (
        <Button className={`card-button ${buttonStatusLean.class}`}>
          {buttonStatusLean.label}
        </Button>
      );

      /** progress */
      if (currentTask.progress.toFixed(2) == '100.00') {
        progressColor = '#34AF00';
      } else if (currentTask.progress > currentTask.expected) {
        progressColor = '#2C3421';
      } else if (currentTask.progress < currentTask.expected) {
        progressColor = '#E50101';
      } else {
        progressColor = '#52c41a';
      }
      columnNameProgress = tableConfigWeekly.find(
        (el) => el.name === 'progress'
      );
      columnNameDuration = tableConfigWeekly.find(
        (el) => el.name === 'duration'
      );
      // const doesExistAtReference = props.getTask(currentTask.parent_id, null, currentActivity)
      if (props.groupBy.criteria === 'activityId') {
        /** no grouped */
        if (currentTask.children?.length /* || isTaskRestricted */) {
          const percentVal = currentTask[columnNameProgress.name].toFixed
            ? currentTask[columnNameProgress.name].toFixed(2)
            : currentTask[columnNameProgress.name];
          blockedProgress = (
            <div>
              <Progress
                strokeColor={{ '0%': progressColor, '100%': progressColor }}
                showInfo={false}
                percent={parseFloat(percentVal)}
                format={
                  percentVal == 100
                    ? false
                    : (percent) =>
                        t('lang') === 'en'
                          ? percent + '%'
                          : `${percent}`.replace('.', ',') + '%'
                }
              />
            </div>
          );
        }
      } else {
        /** grouped */
        const percentVal = currentTask[columnNameProgress.name].toFixed
          ? currentTask[columnNameProgress.name].toFixed(2)
          : currentTask[columnNameProgress.name];
        blockedProgress = (
          <div>
            <Progress
              strokeColor={{ '0%': progressColor, '100%': progressColor }}
              showInfo={false}
              percent={parseFloat(percentVal)}
              format={
                percentVal == 100
                  ? false
                  : (percent) =>
                      t('lang') === 'en'
                        ? percent + '%'
                        : `${percent}`.replace('.', ',') + '%'
              }
            />
          </div>
        );
      }

      /** activity */
      if (currentActivity?.activityRoute) {
        routeString =
          currentActivity.activityRoute + ' > ' + currentActivity.name;
      } else {
        const activityFind = lastLevelActivities.activities.find(
          (act) => act.id === currentTask.activityId
        );
        routeString = `${activityFind?.activityRoute} > ${activityFind?.name}`;
      }

      /** parentTask */
      if (currentTask.parent_id && currentActivity) {
        const doesExistAtReference = props.getTask(
          currentTask.parent_id,
          null,
          currentActivity
        );
        parentTask = doesExistAtReference[0];
      }

      /** cnc status */
      columnCncStatus = tableConfigCncs.find((el) => el.name === 'status');
      columnCncName = tableConfigCncs.find((el) => el.name === 'name');

      /** cost */
      columnCostTotal = tableConfigWeekly.find((el) => el.name === 'cost');
      colCostTotal = renderCol(
        () => {},
        '5-cost-total',
        columnCostTotal,
        currentTask,
        () => {
          handlePopVisibility(false, uniqueId);
        },
        editedInput,
        setEditedInput,
        null
      );

      /** activity data */
      if (currentActivity) {
        ammountCostAssignedToActivity.current = currentActivity.cost;
      }
    }
  };

  processRender();

  /** render Responsables */
  const renderTaskResponsables = () => {
    return (
      <div className="card-avatar">
        <div
          className="custom-multi-select-pop"
          style={{
            width: columnNameResponsable.width
              ? columnNameResponsable.width
              : '100%',
            height: '100%',
            marginTop: 0,
            border: 0
          }}>
          <Popover
            overlayClassName="container-image-custom"
            className="popoverPriority"
            content={
              <SelectSearch
                className="select-search select-search--multiple"
                options={friends}
                value={selected}
                renderOption={renderFriend}
                onChange={async (val) => {
                  currentTask[columnNameResponsable.name] = [];
                  val.map((op) => {
                    currentTask[columnNameResponsable.name].push(
                      dictionary[op]
                    );
                  });
                  updateAsyncTask(currentTask);
                  const user = getSignedUser();
                  currentTask.sectorId = projectState.sectorSelected;
                  for (let i = 0; i < currentTask.responsables.length; i++) {
                    await notificationService.createConstraint({
                      user,
                      constraint: currentTask,
                      module: 'tasks',
                      type_notification: 'assign_responsible',
                      userId: currentTask.responsables[i].id
                    });
                  }
                  dispatch(userActions.setUserUpdate());
                  handlePopVisibility(false, uniqueId);
                  updateLogs();
                }}
                multiple
                search
                placeholder="Buscar responsable"
              />
            }
            trigger="click">
            {currentTask[columnNameResponsable.name].length ? (
              currentTask[columnNameResponsable.name].map(
                (responsable, index) => {
                  return (
                    <span className="vertical-center" key={index}>
                      <Tooltip
                        title={`${responsable.name} ${responsable.lastname}`}>
                        {responsable[columnNameResponsable.img_from] ? (
                          <img
                            className="img-responsable-lookahead"
                            src={responsable[columnNameResponsable.img_from]}
                          />
                        ) : (
                          <div className="img-responsable-lookahead no-img">
                            {responsable.name ? responsable.name[0] : ''}
                            {responsable.lastname
                              ? responsable.lastname[0]
                              : ''}
                          </div>
                        )}
                      </Tooltip>
                    </span>
                  );
                }
              )
            ) : (
              <span className="vertical-center">
                <Tooltip title={t('select_responsable_tooltip')}>
                  <img src={selectResponsablesIcon} width={15} />
                </Tooltip>
              </span>
            )}
          </Popover>
        </div>
      </div>
    );
  };

  /** task name */
  const renderTaskName = () => {
    const columnNameCopy = cloneDeep(columnName);
    columnNameCopy.width = TASK_COLUMN_NAME_WIDTH;
    return (
      <span>
        {
          <EditableInput
            t={t}
            service={updateAsyncTask}
            onEdit={setEditedInput}
            isEditing={editedInput}
            renderEditable={(
              column,
              index,
              task,
              value,
              setValue,
              updateParentData = null,
              handleEsc = null
            ) => {
              return (
                <Input
                  onKeyDown={handleEsc}
                  onPressEnter={() => {
                    updateParentData();
                    updateAsyncTask(currentTask);

                    const doesExistAtReference = findDeepGetTask(
                      activities,
                      'id',
                      currentTask.id
                    );
                    if (doesExistAtReference) {
                      doesExistAtReference.name = currentTask.name;
                      updateAsyncTaskGanttV2(
                        doesExistAtReference,
                        'name',
                        currentTask.name,
                        props.gantt
                      );
                    }
                    updateLogs();
                  }}
                  id={column.name + task.id}
                  size="small"
                  value={value}
                  className="custom-input-planification card-custom-input"
                  onChange={(e) => setValue(e.target.value)}
                  onFocus={(e) => e.target.select()}
                  onBlur={() => {
                    updateParentData();
                    updateAsyncTask(currentTask);

                    const doesExistAtReference = findDeepGetTask(
                      activities,
                      'id',
                      currentTask.id
                    );
                    if (doesExistAtReference) {
                      doesExistAtReference.name = currentTask.name;
                      updateAsyncTaskGanttV2(
                        doesExistAtReference,
                        'name',
                        currentTask.name,
                        props.gantt
                      );
                    }
                    updateLogs();
                  }}
                />
              );
            }}
            index={null}
            updateState={updateState}
            column={columnNameCopy}
            task={currentTask}
            isCard={true}
          />
        }
      </span>
    );
  };

  /** lean status */
  const renderTaskLeanStatus = () => {
    return buttonStatusLean && buttonStatusLean.class !== 'card-restricted' ? (
      <div
        className="customPlanificationBlock priorityFlag fix-status-weeklyplan"
        style={{ height: '100%', marginTop: 0, border: 0 }}>
        {
          <Popover
            placement="bottom"
            overlayClassName="popoverPriority"
            className="popoverPriority"
            content={
              <div className="priorityOptions">
                {columnNameLean.from_values.map((option, index) => {
                  if (
                    currentTask.lean_status === 'Committed' ||
                    currentTask.lean_status === 'Restricted'
                  )
                    return null;
                  if (
                    option.value == 'Committed' ||
                    option.value == 'Restricted'
                  )
                    return null;
                  if (
                    currentViewLookahead?.current.name == 'gantt' &&
                    !currentTask.isInsideWeekly &&
                    option.value == 'Will'
                  )
                    return null;
                  return (
                    <span
                      className="item"
                      key={index}
                      onClick={() => {
                        const childsHaveLeanStatusWill =
                          currentTask.children?.some(
                            (el) => el.lean_status === 'Will'
                          );
                        if (!childsHaveLeanStatusWill) {
                          currentTask[columnNameLean.name] = option.value;
                          updateAsyncTask(currentTask);
                          calculateLeanStatus(
                            currentTask,
                            parentTask || currentActivity,
                            currentActivity,
                            updateAsyncTask,
                            updateAsyncActivity
                          );
                        } else {
                          notifyMessage({
                            title: t('modules.weeklyplan'),
                            message: t('have_childs_will'),
                            type: 'warning'
                          });
                        }

                        handlePopVisibility(false, uniqueIdTask);
                        handlePopVisibilityCloseAll(uniqueIdTask);
                        updateLogs();
                        if (excludeAfterAction) {
                          const excludeStatusLean = [
                            'Will',
                            'Committed'
                          ].includes(option.value);
                          if (!excludeStatusLean) {
                            const newData = getActivitiesforWeeklyPlan(
                              activities,
                              dateRange
                            );
                            updateRender(newData);
                          }
                        }
                      }}>
                      <i
                        className="fas fa-circle"
                        style={{
                          fontSize: 11,
                          color: option.color,
                          position: 'relative',
                          top: -1
                        }}
                      />
                      <span style={{ marginLeft: 5 }}>
                        {capitalize(option.label)}
                      </span>
                    </span>
                  );
                })}
              </div>
            }
            trigger="click">
            {contentLean}
          </Popover>
        }
      </div>
    ) : (
      <Button className={`card-button ${buttonStatusLean.class}`}>
        {buttonStatusLean.label}
      </Button>
    );
  };

  /** start date */
  const renderTaskStarDate = () => {
    /** disabled by progress from gantt view */
    let disabledProgress = false;
    if (
      currentViewLookahead?.current.name == 'gantt' &&
      currentTask.progress > 99.999
    ) {
      disabledProgress = true;
    }

    return (
      <DatePicker
        disabled={currentTask.children?.length || onlyRead || disabledProgress}
        id={'start_date' + currentTask.id}
        allowClear={false}
        className="custom-date-picker-planification"
        defaultValue={moment(currentTask.start_date)}
        format={formatDate}
        onChange={(date, dateString) => {
          currentTask.start_date = moment(dateString, formatDate).format(
            'YYYY/MM/DD'
          );
          getEndDateByGantt(currentTask);
          /** Apply changes */
          updateAsyncTask(currentTask);
          updateState();
          updateLogs();
          if (excludeAfterAction) {
            const belongs = belongsToRange(
              moment(dateString, formatDate).format('YYYY/MM/DD'),
              [{ ...dateRange }]
            );
            if (!belongs) {
              const newData = getActivitiesforWeeklyPlan(activities, dateRange);
              updateRender(newData);
            }
          }
          handlePopVisibility(false, uniqueId);
          if (!date || !date.toDate) return;
          launchInlineEditorEvent(gantt)(
            currentTask,
            'start_date',
            date.toDate()
          );
        }}
      />
    );
  };

  const renderTaskDuration = () => {
    const index = 'duration-task' + currentTask.id;
    /** disabled by progress from gantt view */
    let disabledProgress = false;
    if (
      currentViewLookahead?.current.name == 'gantt' &&
      currentTask.progress > 99.999
    ) {
      disabledProgress = true;
    }
    return (
      <EditableInput
        t={t}
        disabled={
          disabledProgress ||
          onlyRead ||
          props.groupBy.criteria !== 'activityId'
        }
        service={updateAsyncTask}
        onEdit={setEditedInput}
        isEditing={editedInput}
        key={index}
        isFloat={!Number.isInteger(currentTask[columnNameDuration.name])}
        renderEditable={(
          column,
          index,
          task,
          value,
          setValue,
          updateParentData = null,
          handleEsc = null
        ) => (
          <InputNumber
            onKeyDown={handleEsc}
            onPressEnter={(e) => {
              if (!e || !e.target || !e.target.value) return;
              task[column.name] = e.target.value;
              task.durationDays = e.target.value;

              /** We give the lookahead task to gantt API to get duration and end_date */
              getEndDateByGantt(task, currentActivity);

              /** Calculates ponderators feature */
              calculatePonderators(
                parentTask || currentActivity,
                currentActivity,
                updateAsyncTask,
                projectState
              );

              /** Apply changes */
              updateAsyncTask(task);
              updateLogs();
              handlePopVisibility(false, uniqueId);
              updateParentData();
              launchInlineEditorEvent(gantt)(task, column.name, e.target.value);
            }}
            onBlur={(e) => {
              task[column.name] = value;
              task.durationDays = value;

              /** We give the lookahead task to gantt API to get duration and end_date */
              getEndDateByGantt(task, currentActivity);

              /** Calculates ponderators feature */
              calculatePonderators(
                parentTask || currentActivity,
                currentActivity,
                updateAsyncTask,
                projectState
              );

              /** Apply changes */
              updateAsyncTask(task);
              updateLogs();
              handlePopVisibility(false, uniqueId);
              updateParentData();
              if (!column || !column.name) return;
              launchInlineEditorEvent(gantt)(task, column.name, value);
            }}
            className="custom-input-number-planification ant-input-number-focused card-custom-input-number"
            disabled={disabled}
            min={0}
            name={column.name + task.id}
            id={column.name + task.id}
            precision={Number.isInteger(task[column.name]) ? 0 : 2}
            step={0.1}
            defaultValue={task[column.name]}
            onChange={(e) => setValue(e)}
            onFocus={(e) => e.target.select()}
          />
        )}
        updateState={updateState}
        index={index}
        column={columnNameDuration}
        task={currentTask}
        isCard={true}
      />
    );
  };

  /** render card header */
  const renderCardHeader = () => {
    return (
      <div className="card-header">
        {/* render task responsables */}
        {renderTaskResponsables()}

        <div className="card-info">
          {/* render task name */}
          {renderTaskName()}

          {/* status lean */}
          {renderTaskLeanStatus()}

          <div className="card-dates">
            {/* task start date */}
            <div className="fini">{renderTaskStarDate()}</div>

            <div className="duration">{renderTaskDuration()}</div>
            <div className="fend">
              {moment(currentTask.end_date).format(formatDate)}
            </div>
          </div>
        </div>
        <div className="card-route">{routeString}</div>
      </div>
    );
  };

  /** tabs */
  /** render tabs header */
  const renderTabsHeader = () => {
    const tabs = [
      {
        label: t('card.restricciones'),
        index: 0
      },
      {
        label: t('card.cncs'),
        index: 1
      }
    ];

    return (
      <div className="buttons-tab">
        {tabs.map((tab) => (
          <div
            key={tab.index}
            className={`btn-tab ${currentTab === tab.index ? 'btn-tab-active' : null}`}
            onClick={() => setTab(tab.index)}>
            <CheckCircleOutlined className="icon-btn-tab" />
            {tab.label}
          </div>
        ))}
      </div>
    );
  };

  const orderStatus = (status) => {
    let finalStatus = 0;
    switch (status) {
      case 'expired':
        finalStatus = 1;
        break;
      case 'notreleased':
        finalStatus = 2;
        break;
      case 'draft':
        finalStatus = 3;
        break;
      case 'notcompromises':
        finalStatus = 4;
        break;
      case 'released':
        finalStatus = 5;
        break;
    }
    return finalStatus;
  };

  /** render constraints table */
  const renderConstraintsTable = () => {
    return (
      <div className={`card-constraints ${currentTab !== 0 ? 'hidden' : null}`}>
        {currentTask.constraints.length ? (
          currentTask.constraints
            .sort((a, b) => {
              const aStatus = orderStatus(a.status);
              const bStatus = orderStatus(b.status);
              return aStatus > bStatus ? 1 : -1;
            })
            .map((constraint) => {
              // console.log(currentTask, 'ccc')
              const statusObject = statusArr.filter(
                (el) => el.value == constraint.status
              );
              const uniqueIdConstraint = constraint.id + 'constraint';
              const backgroundStatus = statusObject[0].color;
              const responsable = toSelectResponsables.find(
                (us) => us.id === constraint.userId
              );
              const customPermission = hasEditPermission(
                permission,
                user.id,
                responsable?.id
              );
              let classDate = 'custom-date-picker-planification';
              if (constraint.active) {
                classDate += ' massive';
              }
              return (
                <div className="row-constraint" key={constraint.id}>
                  <div className="col-card-constraint user">
                    <span className="vertical-center">
                      {!responsable && (
                        <Tooltip title={t('select_responsable_tooltip')}>
                          <img src={selectResponsablesIcon} width={15} />
                        </Tooltip>
                      )}
                      {responsable && (
                        <Tooltip
                          title={`${responsable.name} ${responsable.lastname}`}>
                          {!responsable.image && (
                            <div className="img-responsable-lookahead no-img">
                              {responsable.name ? responsable.name[0] : ''}
                              {responsable.lastname
                                ? responsable.lastname[0]
                                : ''}
                            </div>
                          )}
                          {responsable.image && (
                            <img
                              className="img-responsable-lookahead"
                              src={responsable.image}
                            />
                          )}
                        </Tooltip>
                      )}
                    </span>
                  </div>
                  {/* constraint name */}
                  <div className="col-card-constraint name">
                    {
                      <EditableInput
                        t={t}
                        service={updateAsyncConstraint}
                        onEdit={setEditedInput}
                        isEditing={editedInput}
                        disabled={customPermission}
                        renderEditable={(
                          column,
                          index,
                          task,
                          value,
                          setValue,
                          updateParentData = null,
                          handleEsc = null
                        ) => {
                          return (
                            <Input
                              onKeyDown={handleEsc}
                              onPressEnter={() => {
                                updateParentData();
                                updateAsyncConstraint(constraint);
                              }}
                              id={column.name + task.id}
                              size="small"
                              value={value}
                              disabled={customPermission}
                              className="custom-input-planification card-custom-input"
                              onChange={(e) => setValue(e.target.value)}
                              onFocus={(e) => e.target.select()}
                            />
                          );
                        }}
                        index={null}
                        updateState={updateState}
                        column={columnName}
                        task={constraint}
                        isCard={true}
                      />
                    }
                  </div>
                  {/* status */}
                  <div className="col-card-constraint status">
                    <div
                      className="customPlanificationBlock priorityFlag"
                      style={{ height: '100%', marginTop: 0, border: 0 }}>
                      <Tooltip placement="top">
                        <Popover
                          placement="bottom"
                          overlayClassName="popoverPriority"
                          className="card-constraint-status"
                          disabled={customPermission}
                          onClick={() => {
                            if (customPermission) return;
                            handlePopVisibilityCloseAll(uniqueIdConstraint);
                            popsVisibility[uniqueIdConstraint]
                              ? handlePopVisibility(
                                  !popsVisibility[uniqueIdConstraint],
                                  uniqueIdConstraint
                                )
                              : handlePopVisibility(true, uniqueIdConstraint);
                          }}
                          visible={
                            popsVisibility[uniqueIdConstraint]
                              ? popsVisibility[uniqueIdConstraint]
                              : false
                          }
                          content={
                            <div className="priorityOptions">
                              {statusArr
                                .filter((el) => el.visible)
                                .map((option, index) => {
                                  /** exceptions (constraints life cycle ) */
                                  if (
                                    constraint.status === 'released' &&
                                    option.value === 'released'
                                  )
                                    return false;
                                  if (
                                    constraint.status === 'notreleased' &&
                                    option.value === 'notreleased'
                                  )
                                    return false;
                                  if (
                                    constraint.status === 'expired' &&
                                    option.value === 'notreleased'
                                  )
                                    return false;
                                  if (
                                    constraint.status === 'notcompromises' &&
                                    option.value === 'notreleased'
                                  )
                                    return false;
                                  if (
                                    constraint.status === 'notcompromises' &&
                                    option.value === 'released'
                                  )
                                    return false;
                                  return (
                                    <span className="item" key={index}>
                                      <Popconfirm
                                        onConfirm={() =>
                                          changeStatusConstraint(
                                            constraint,
                                            option,
                                            uniqueIdConstraint
                                          )
                                        }
                                        title={t('are_sure_general')}
                                        icon={
                                          <Icon
                                            type="question-circle-o"
                                            style={{ color: 'red' }}
                                          />
                                        }>
                                        <i
                                          className="fas fa-circle"
                                          style={{
                                            fontSize: 11,
                                            color: option.color,
                                            position: 'relative',
                                            top: -1
                                          }}
                                        />
                                        <span style={{ marginLeft: 5 }}>
                                          {option.label}
                                        </span>
                                      </Popconfirm>
                                    </span>
                                  );
                                })}
                            </div>
                          }
                          trigger="click">
                          <div
                            style={{
                              backgroundColor: backgroundStatus,
                              color: 'white',
                              height: '100%',
                              cursor: customPermission
                                ? 'not-allowed'
                                : 'pointer'
                            }}>
                            {/* <span className="vertical-center"> */}
                            {statusObject[0].label}
                            {/* </span> */}
                          </div>
                        </Popover>
                      </Tooltip>
                    </div>
                  </div>
                  {/* commitment date */}
                  <div className="col-card-constraint commitment-date">
                    {constraint.commitmentDate ? (
                      <span className="vertical-center-dates">
                        <DatePicker
                          allowClear={false}
                          id={'commitmentDate' + currentTask.id}
                          className={classDate}
                          value={moment(constraint.commitmentDate)}
                          format={formatDate}
                          disabled={customPermission}
                          onChange={async (date, dateString) => {
                            showConfirm(
                              constraint,
                              moment(dateString, formatDate).format(
                                'YYYY/MM/DD'
                              )
                            );
                          }}
                        />
                      </span>
                    ) : (
                      <span className="vertical-center">
                        <Popover
                          overlayClassName="popoverConstraint"
                          disabled={customPermission}
                          content={
                            <div>
                              <DatePicker
                                className="dateConstraint"
                                allowClear
                                format={formatDate}
                                disabled={customPermission}
                                onChange={async (e, dateString) => {
                                  let status = constraint.status;
                                  if (
                                    moment(e, formatDate).format('YYYY-MM-DD') <
                                    moment().format('YYYY-MM-DD')
                                  ) {
                                    status = 'expired';
                                  } else {
                                    status = 'notreleased';
                                  }
                                  constraint.status = status;
                                  constraint.commitmentDate = moment(
                                    e,
                                    formatDate
                                  ).format('YYYY-MM-DD');
                                  const addSch = await createSchedule(
                                    moment(dateString, formatDate).format(
                                      'YYYY-MM-DD'
                                    ),
                                    constraint.id
                                  );
                                  constraint.schedules
                                    ? constraint.schedules.push(addSch)
                                    : (constraint.schedules = [addSch]);
                                  updateAsyncConstraint(constraint);
                                  updateState();
                                }}
                              />
                            </div>
                          }
                          title={t('change_committed_only')}
                          trigger="click">
                          <Button
                            className="constraints-commitment-btn card-btn-date-commitment"
                            disabled={customPermission}
                            size="small">
                            <div>
                              <IconComponent
                                data={handshake}
                                width={12}
                                fill="#FFFFFF"
                                className="icon-hand-shake"
                              />
                              <span className="constraint-btn-span">
                                {t('committed_text_general')}
                              </span>
                            </div>
                          </Button>
                        </Popover>
                      </span>
                    )}
                  </div>
                </div>
              );
            })
        ) : (
          <div className="row-constraint">
            <div className="col-center-wide">
              {t('lookahead_constraints.no_constraints')}
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleVisible = (visible, uniqueIdCnc) => {
    handlePopVisibility(true, uniqueIdCnc);
  };

  /** render cncs table */
  const renderCncsTable = () => {
    return (
      <div className={`card-cncs ${currentTab !== 1 ? 'hidden' : null}`}>
        {cncsTask.cncs && cncsTask.cncs.length ? (
          cncsTask.cncs &&
          cncsTask.cncs.map((cnc) => {
            const cncTask = cnc.cnctasks.find(
              (el) => el.taskId === currentTask.id
            );
            const statusObject = columnCncStatus.from_values.filter(
              (el) => el.value == cnc.status
            );
            const background = statusObject[0].color;
            const uniqueIdCnc = cnc.id + 'cnc';
            return (
              <div className="row-cncs">
                <div className="col-card-cnc week">
                  {t('weekly_week')} {cnc.week}
                </div>
                <div className="col-card-cnc percentage">
                  {cncTask.commitment_percentaje}% /{' '}
                  {cncTask.realized_percentaje}%
                </div>
                <div className="col-card-cnc user card-avatar-cncs">
                  {cnc.user ? (
                    <span className="vertical-center">
                      <Tooltip
                        title={cnc.user.name + ' (' + cnc.user.email + ')'}>
                        {cnc.user.image ? (
                          <img
                            className="img-responsable-lookahead"
                            src={cnc.user.image}
                          />
                        ) : (
                          <div className="img-responsable-lookahead no-img">
                            {cnc.user.name[0]}
                          </div>
                        )}
                      </Tooltip>
                    </span>
                  ) : null}
                </div>
                <div className="col-card-cnc name">
                  {
                    <EditableInput
                      t={t}
                      service={updateAsyncCnc}
                      onEdit={setEditedInput}
                      isEditing={editedInput}
                      renderEditable={(
                        column,
                        index,
                        task,
                        value,
                        setValue,
                        updateParentData = null,
                        handleEsc = null
                      ) => {
                        return (
                          <Input
                            onKeyDown={handleEsc}
                            onPressEnter={() => {
                              updateParentData();
                              setEditedInput(cnc);
                            }}
                            id={column.name + task.id}
                            size="small"
                            value={value}
                            className="custom-input-planification card-custom-input"
                            onChange={(e) => setValue(e.target.value)}
                            onFocus={(e) => e.target.select()}
                          />
                        );
                      }}
                      index={null}
                      updateState={updateState}
                      column={columnCncName}
                      task={cnc}
                      isCard={true}
                    />
                  }
                </div>
                <div className="col-card-cnc status">
                  <div
                    className="customPlanificationBlock priorityFlag"
                    style={{ height: '100%', marginTop: 0, border: 0 }}>
                    <Tooltip placement="top">
                      <Popover
                        placement="bottom"
                        overlayClassName="popoverPriority"
                        className="card-cnc-status"
                        onVisibleChange={(visible) =>
                          handleVisible(visible, uniqueIdCnc)
                        }
                        content={
                          <div className="priorityOptions">
                            {columnCncStatus.from_values.map(
                              (option, index) => {
                                return (
                                  <span
                                    className="item"
                                    key={index}
                                    onClick={() => {
                                      cnc.status = option.value;
                                      updateAsyncCnc(cnc);
                                      handlePopVisibility(false, uniqueIdCnc);
                                    }}>
                                    <i
                                      className="fas fa-circle"
                                      style={{
                                        fontSize: 11,
                                        color: option.color,
                                        position: 'relative',
                                        top: -1
                                      }}
                                    />
                                    <span style={{ marginLeft: 5 }}>
                                      {option.label}
                                    </span>
                                  </span>
                                );
                              }
                            )}
                          </div>
                        }
                        trigger="click">
                        <div
                          style={{
                            backgroundColor: background,
                            color: 'white',
                            height: '100%'
                          }}>
                          {statusObject[0].label}
                        </div>
                      </Popover>
                    </Tooltip>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="row-cncs">
            <div className="col-card-cnc col-center-wide">
              {t('card.no_cncs')}
            </div>
          </div>
        )}
      </div>
    );
  };

  /** body subcontracts */
  const renderSubcontracts = () => {
    return (
      <div
        className="customPlanificationBlock priorityFlag fix-inline card-subcontract"
        style={{ height: '100%', marginTop: 0, border: 0 }}>
        <Popover
          overlayClassName="popover-subcontract"
          className="popover-subcontract"
          placement="bottom"
          onClick={() => {
            setPopSubcontractVisible(!popSubcontractVisible);
          }}
          content={
            <div
              className="subcontracts-options"
              style={{ maxHeight: 600, overflow: 'auto' }}>
              {subContracts.map((option, index) => {
                return (
                  <span
                    className="item"
                    key={index}
                    onClick={() => {
                      currentTask.subcontractId = option.id;
                      currentTask.subcontract = option;
                      updateAsyncTask(currentTask);
                      handlePopVisibility(false, uniqueId);
                      setPopSubcontractVisible(false);
                      updateRender();
                    }}>
                    <span style={{ marginLeft: 5 }}>
                      <span
                        className="subcontract-options-border"
                        style={{
                          border: '1px solid ' + option.color,
                          color: option.color
                        }}>
                        <IconComponent
                          data={helmet}
                          width={15}
                          fill={option.color}
                          className="icon-options-subcontract"
                        />
                        {capitalize(option.name)}
                      </span>
                    </span>
                  </span>
                );
              })}
              <span
                className="add-subcontract-inline"
                onClick={() => setVisibleFormSubcontract(true)}>
                + {t('create')}
              </span>
            </div>
          }
          trigger="click">
          {subContractObject ? (
            <span
              className="subcontract-border"
              style={{
                border: '1px solid ' + colorObject,
                color: colorObject,
                padding: 5,
                borderRadius: 5
              }}>
              <IconComponent
                style={{ marginRight: 6, position: 'relative', top: 2 }}
                data={helmet}
                width={15}
                fill={colorObject}
                className="icon-subcontract"
              />
              {subContractObject.name}
            </span>
          ) : (
            <span className="">{t('card.no_subcontracts')}</span>
          )}
        </Popover>
        {subContractObject ? (
          <Tooltip title={t('weekly_remove_subcontract')}>
            <CloseCircleOutlined
              className="delete-subcontract-lookahead"
              onClick={() => deleteSubcontract(currentTask, currentActivity)}
            />
          </Tooltip>
        ) : null}
      </div>
    );
  };

  /** body Priority */
  const renderPriority = () => {
    return (
      <div className="customStr priorityFlag fix-inline card-priority">
        <Popover
          placement="bottom"
          overlayClassName="popoverPriority"
          className="popoverPriority"
          onClick={() => {
            setPopPriorityVisible(!popPriorityVisible);
          }}
          content={
            <div className="priorityOptions">
              {priorityArr.map((option, index) => {
                return (
                  <span
                    className="item"
                    key={index}
                    onClick={() => {
                      currentTask.priority = option.value;
                      updateAsyncTask(currentTask);
                      handlePopVisibility(false, uniqueId);
                      setPopPriorityVisible(false);
                      updateLogs();
                    }}>
                    {defineIcon(option)}
                    <span style={{ marginLeft: 5 }}>
                      {capitalize(option.label)}
                    </span>
                  </span>
                );
              })}
            </div>
          }
          trigger="click">
          {defineIcon(iconToShow[0])}
        </Popover>
      </div>
    );
  };

  const renderCommitment = () => {
    return !currentTask.restricted && commitmentPercentaje !== null ? (
      <span className="btn-commitment">
        <IconComponent
          width={16}
          data={handshake}
          fill="#7DFF8A"
          className="card-icon-hand-shake"
        />
        {commitmentPercentaje
          ? t('lang') === 'en'
            ? parseFloat(commitmentPercentaje).toFixed(2) + '%'
            : parseFloat(commitmentPercentaje).toFixed(2).replace('.', ',') +
              '%'
          : null}
      </span>
    ) : (
      t('card.no_commitment')
    );
  };

  const updateBar = () => {
    handlePopVisibility(false, currentTask.id + 'task');
  };

  /** render Progress type bar */
  const renderProgressBar = () => {
    return progressForBar !== undefined
      ? blockedProgress || (
          <ProgressBarCard
            t={t}
            fillcolor={progressColor}
            calculateProgress={calculateProgress}
            calculatePonderators={calculatePonderators}
            projectState={projectState}
            updateAsyncActivity={updateAsyncActivity}
            updateAsyncTask={updateAsyncTask}
            updateRender={updateState}
            updateTask={updateTask}
            parent={parentTask || currentActivity}
            task={currentTask}
            activity={currentActivity}
            column={columnNameProgress}
            type="bar" /** bar, input */
            progress={progressForBar || { value: 0 }}
            setProgress={setProgressForBar}
            updateRenderView={updateBar}
            updateLogs={updateLogs}
          />
        )
      : null;
  };

  /** render Progress type input */
  const renderProgressInput = () => {
    return (
      <div className="progress-real">
        {progressForBar !== undefined ? (
          <ProgressBarCard
            t={t}
            fillcolor={progressColor}
            calculateProgress={calculateProgress}
            calculatePonderators={calculatePonderators}
            projectState={projectState}
            updateAsyncActivity={updateAsyncActivity}
            updateAsyncTask={updateAsyncTask}
            updateRender={updateState}
            updateTask={updateTask}
            parent={parentTask || currentActivity}
            task={currentTask}
            activity={currentActivity}
            column={columnNameProgress}
            type="input" /** bar, input */
            progress={progressForBar || { value: 0 }}
            setProgress={setProgressForBar}
            blocked={!!blockedProgress}
            updateRenderView={updateBar}
            updateLogs={updateLogs}
          />
        ) : null}
      </div>
    );
  };

  const resetHooks = () => {
    onCloseCard();
    setCncsTask([]);
  };

  const arrFields = {
    name: t('card.log_fields.name'),
    start_date: t('card.log_fields.start_date'),
    progress: t('card.log_fields.progress'),
    priority: t('card.log_fields.priority'),
    duration: t('card.log_fields.duration'),
    ponderator: t('card.log_fields.ponderator'),
    cost: t('card.log_fields.cost'),
    responsables: t('card.log_fields.responsables'),
    status: t('card.log_fields.status'),
    lean_status: t('card.log_fields.lean_status'),
    total_quantity: t('card.log_fields.total_quantity'),
    actual_quantity: t('card.log_fields.actual_quantity'),
    remaining_quantity: t('card.log_fields.remaining_quantity'),
    won_hh: t('card.log_fields.won_hh'),
    hhWorkTime: t('card.log_fields.hhWorkTime'),
    total_hm: t('card.log_fields.total_hm'),
    spend_hm: t('card.log_fields.spend_hm')
  };

  /** render logs  */
  const getLogToRender = (arrLog) => {
    const arrLogReverse = Array.isArray(arrLog) ? arrLog.reverse() : [];
    const renderFinish = [];
    arrLogReverse.map((log, i) => {
      const regCurrent = log;
      const regPrev = arrLogReverse[i + 1];
      if (log.userId && regCurrent && regPrev) {
        Object.keys(arrFields).map((field) => {
          try {
            let oldValue = regPrev.body[field] || 0;
            let newValue = regCurrent.body[field] || 0;

            /** validations dont exist or numeric */
            if (isFloat(oldValue)) {
              oldValue =
                t('lang') === 'en'
                  ? formatMoney(oldValue, '', 2, '.', ',')
                  : formatMoney(oldValue, '', 2, ',', '.');
              newValue =
                t('lang') === 'en'
                  ? formatMoney(parseFloat(newValue), '', 2, '.', ',')
                  : formatMoney(parseFloat(newValue), '', 2, ',', '.');
            }
            if (oldValue === undefined) {
              oldValue = 0;
            }
            if (isFloat(newValue)) {
              newValue =
                t('lang') === 'en'
                  ? formatMoney(newValue, '', 2, '.', ',')
                  : formatMoney(newValue, '', 2, ',', '.');
              oldValue =
                t('lang') === 'en'
                  ? formatMoney(parseFloat(oldValue), '', 2, '.', ',')
                  : formatMoney(parseFloat(oldValue), '', 2, ',', '.');
            }
            if (newValue === undefined) {
              newValue = 0;
            }

            /** format fields */
            if (field === 'start_date') {
              oldValue = moment(oldValue).format(formatDate);
              newValue = moment(newValue).format(formatDate);
            }
            if (field === 'progress') {
              oldValue += '%';
              newValue += '%';
            }
            if (field === 'cost') {
              oldValue = `$ ${oldValue}`;
              newValue = `$ ${newValue}`;
            }
            if (field === 'lean_status') {
              oldValue = columnNameLean.from_values.find(
                (el) => el.value === oldValue
              ).label;
              newValue = columnNameLean.from_values.find(
                (el) => el.value === newValue
              ).label;
            }
            if (field === 'status') {
              const taskStatusValues = taskStatus(t);
              const findOldValue = taskStatusValues.find(
                (el) => el.value === oldValue
              );
              const findNewValue = taskStatusValues.find(
                (el) => el.value === newValue
              );
              oldValue = findOldValue ? findOldValue.label : oldValue;
              newValue = findNewValue ? findNewValue.label : newValue;
            }
            if (field === 'priority') {
              oldValue = priorityArr.find((el) => el.value === oldValue).label;
              newValue = priorityArr.find((el) => el.value === newValue).label;
            }
            let diffResponsables = null;
            let action = 'add';
            if (field === 'responsables') {
              diffResponsables = newValue.filter(
                ({ id: id1 }) => !oldValue.some(({ id: id2 }) => id2 === id1)
              );
              if (newValue.length < oldValue.length) {
                action = 'remove';
                diffResponsables = oldValue.filter(
                  ({ id: id1 }) => !newValue.some(({ id: id2 }) => id2 === id1)
                );
              }
            }
            if (newValue !== oldValue || diffResponsables) {
              let userToShow = t('settings.user');
              if (log.userId.id.name && log.userId.id.lastname) {
                userToShow = log.userId.id.name + ' ' + log.userId.id.lastname;
              } else {
                const userData = toSelectResponsables.find(
                  (el) => el.id === log.userId.id.id
                );
                if (userData) {
                  if (userData.name && userData.lastname) {
                    userToShow = userData.name + ' ' + userData.lastname;
                  }
                }
              }

              if (diffResponsables && diffResponsables.length) {
                /** case responsables */
                const responsable = `${diffResponsables[0].name} ${diffResponsables[0].lastname} (${diffResponsables[0].email})`;
                renderFinish.push({
                  time: log.time,
                  user: userToShow,
                  description:
                    action === 'add'
                      ? `${t('card.add')} ${t('card.log_fields.responsable')}: '${responsable}'`
                      : `${t('card.remove')} ${t('card.log_fields.responsable')}: '${responsable}'`
                });
              } else {
                if (field !== 'responsables') {
                  /** other case */
                  renderFinish.push({
                    time: log.time,
                    user: userToShow,
                    description: `${t('card.edit')} ${arrFields[field]} ${t('card.from')} '${oldValue}' ${t('card.to')} '${newValue}'`
                  });
                }
              }
            }
          } catch (e) {
            console.log(e);
          }
        });
      }
    });
    return renderFinish;
  };

  const isFloat = (n) => {
    return n != '' && !isNaN(n) && Math.round(n) != n;
  };

  const renderLogs = (arrLog) => {
    return arrLog.map((log, i) => {
      const timeWithTz = moment(log.time)
        .add(moment(log.time).utcOffset(), 'minutes')
        .format('YYYY/MM/DD HH:mm');
      return (
        <div className="row-logs" key={i}>
          <Tooltip
            title={`${timeWithTz} ${log.user} ${log.description}`}
            trigger="click">
            <span className="card-log-time">{`${timeWithTz}`}</span>:&nbsp;
            <span className="card-log-user">{`${log.user}`}</span>&nbsp;
            {`${log.description}`}
          </Tooltip>
        </div>
      );
    });
  };

  /** render */
  if (!formatDate) return <div></div>;

  return currentTask ? (
    <Drawer
      className="card-drawer"
      height={350}
      placement="bottom"
      onClose={() => resetHooks()}
      visible={cardState.visible}
      key="bottom"
      keyboard
      closable={true}
      drawerStyle={{ background: '#3E3E3E', color: 'white' }}>
      {/* render card header */}
      {renderCardHeader()}

      <div className="card-body">
        <div className="card-column card-first">
          <div>
            {/* subcontracts */}
            {renderSubcontracts()}

            {/* priority */}
            {renderPriority()}

            <div className="column-progress">
              <div className="progress-real">{renderPgInp || null}</div>
              <div className="progress-commitment">{renderCommitment()}</div>
            </div>
          </div>

          <div className="card-progress-bar">{renderPgBar || null}</div>
        </div>

        {/* Tabs */}
        <div className="card-column card-second">
          {/* render header tabs */}
          {renderTabsHeader()}

          {/* render constraints table */}
          {renderConstraintsTable()}

          {/* render cncs table */}
          {renderCncsTable()}
        </div>

        {/* activity logs */}
        <div className="card-column card-third">
          <div className="card-logs1">
            <UnorderedListOutlined /> &nbsp; {t('card.actividad_logs')}
            <div className="card-logs">
              {logsTask && logsTask.length ? (
                <div className="log-div">
                  {logToRender ? renderLogs(logToRender) : null}
                </div>
              ) : (
                <div className="row-logs">
                  <div className="col-card-log col-center-wide">
                    {t('card.no_logs')}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  ) : null;
}
