/* eslint-disable prefer-const */

/** React components  */
import React from 'react';
import { useTranslation } from 'react-i18next';

//* Moment for dates (whit locale)*/
import moment from 'moment';
import 'moment/locale/es';

/** Import elements from library Antd */
import { Tooltip } from 'antd';

import { CircleCheckIcon } from '../../../icons';
import { Colors } from '../../../constants/colors.constants.js';

/** Redux implementation */
import { useSelector } from 'react-redux';

import CalendarOptions from './CalendarOptions/CalendarOptions';

moment.locale('es');

export default function CalendarItem(props) {
  /** Redux */
  const calendarState = useSelector((state) => state.calendarState);
  const calendarSelected = calendarState.calendarSelected;

  /** use props */
  const { item, handleClickItem, setCalendarsList } = props;
  const { t } = useTranslation();

  /** render */
  return (
    <div
      className={
        calendarSelected && calendarSelected.id === item.id
          ? 'itemListGen active'
          : 'itemListGen'
      }>
      <div className="itemHeader">
        <div className="titleCalendar">{item.name}</div>
        <div className="actions">
          <div>
            {t('lang') === 'es' ? 'Creado por: ' : 'Created by: '}
            {item.user
              ? item.user.name + ' ' + item.user.lastname
              : t('lang') === 'es'
                ? 'Sin usuario'
                : 'No user'}
          </div>
          <CalendarOptions
            calendarId={item.id}
            calendarName={item.name}
            isDefault={item.is_default}
            handleClickItem={handleClickItem}
            setCalendarsList={setCalendarsList}
          />
        </div>
      </div>
      <div className="itemSubTitle">
        <div className="subTitleCalendar">
          {item.sector && item.sector.project.name},{' '}
          {item.sector && item.sector.name}
        </div>
      </div>
      <div className="itemFooter">
        <div className="created">
          {t('lang') === 'es' ? 'Creado: ' : 'Created: '}
          {moment(item.createdAt).format('LL')}
        </div>
        <div className="count">
          {item.is_default && (
            <Tooltip title="Calendario por defecto">
              <CircleCheckIcon color={Colors.DARK_MINT} />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
}
