import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { trackingEvent } from '../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../../analytics/utils';
import { activityService } from '../../../../services';
import { getPermissionsByCommand } from '../../../../utils/partialPermissionUtils';
import {
  CloneCalendarOutlineIcon,
  ImportHolidaysOutlineIcon,
  TrashOutlineIcon
} from '../../../../icons';
import Colors from '../../../../assets/styles/variables.scss';
import notificationSystemV2 from '../../../DesignSystem/NotificationSystemV2/NotificationSystemV2';

export const useCalendarOptions = (calendarId, isDefault) => {
  const { DARK_MINT } = Colors;

  const { t } = useTranslation();

  const optionsPopoverRef = useRef();

  const { mutateAsync } = useMutation({
    mutationFn: () => activityService.getCalendarActivities(calendarId),
    retry: 0
  });

  const [isCalendarCloneModalVisible, setIsCalendarCloneModalVisible] =
    useState(false);
  const [isCalendarImportHolidaysVisible, setIsCalendarImportHolidaysVisible] =
    useState(false);
  const [isCalendarDeleteConflictVisible, setIsCalendarDeleteConflictVisible] =
    useState(false);
  const [
    isCalendarDeleteConfirmationVisible,
    setIsCalendarDeleteConfirmationVisible
  ] = useState(false);

  const handleOpenCalendarCloneModal = () => {
    handleClosePopover();

    setIsCalendarCloneModalVisible(true);

    trackingEvent(
      'clone_calendar',
      { ...getBasicAmplitudEventProperties() },
      AMPLITUDE_SERVICE
    );
  };

  const handleOpenImportHolidaysModal = () => {
    handleClosePopover();

    setIsCalendarImportHolidaysVisible(true);

    trackingEvent(
      'import_holidays',
      { ...getBasicAmplitudEventProperties() },
      AMPLITUDE_SERVICE
    );
  };

  const handleOpenDeleteCalendarModal = async () => {
    handleClosePopover();

    try {
      const response = await mutateAsync();

      if (!response) {
        return;
      }

      const { activities } = response;

      if (activities.length) {
        setIsCalendarDeleteConflictVisible(true);
        return;
      }

      setIsCalendarDeleteConfirmationVisible(true);
    } catch (err) {
      notificationSystemV2({
        key: `error-get-activities-calendar-${calendarId}`,
        type: 'error',
        message: t(
          'master_plan_calendars.modal_delete_confirmation.error_message_toast'
        )
      });
    }
  };

  const handleClosePopover = () => {
    if (optionsPopoverRef.current) {
      optionsPopoverRef.current.tooltip.state.visible = false;
    }
  };

  const optionsPopoverItems = [
    {
      key: 1,
      name: t('master_plan_calendars.options.clone'),
      active: false,
      icon: <CloneCalendarOutlineIcon color={DARK_MINT} />,
      disabled: !getPermissionsByCommand('CLONE', 'MASTERPLAN', 'CALENDARS'),
      onClick: () => handleOpenCalendarCloneModal(),
      subitems: [],
      group: []
    },
    {
      key: 2,
      name: t('master_plan_calendars.options.import_holidays'),
      active: false,
      icon: <ImportHolidaysOutlineIcon color={DARK_MINT} />,
      disabled: !getPermissionsByCommand(
        'IMPORT_HOLIDAYS',
        'MASTERPLAN',
        'CALENDARS'
      ),
      onClick: () => handleOpenImportHolidaysModal(),
      subitems: [],
      group: []
    },
    {
      key: 3,
      name: t('master_plan_calendars.options.delete'),
      active: false,
      icon: <TrashOutlineIcon color={DARK_MINT} />,
      disabled:
        !getPermissionsByCommand('DELETE', 'MASTERPLAN', 'CALENDARS') ||
        isDefault,
      onClick: () => handleOpenDeleteCalendarModal(),
      subitems: [],
      group: []
    }
  ];

  return {
    optionsPopoverRef,
    optionsPopoverItems,
    isCalendarCloneModalVisible,
    setIsCalendarCloneModalVisible,
    isCalendarImportHolidaysVisible,
    setIsCalendarImportHolidaysVisible,
    isCalendarDeleteConflictVisible,
    setIsCalendarDeleteConflictVisible,
    isCalendarDeleteConfirmationVisible,
    setIsCalendarDeleteConfirmationVisible
  };
};
