import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../constants/colors.constants';
import { WarningNewIcon } from '../../../icons';
import ButtonSystem from '../../DesignSystem/ButtonSystem';
import ModalSystem from '../../DesignSystem/ModalSystem';
import { mismatchedMessageWithLink } from '../utils/knowledgebaseUtils';

const { YELLOW } = Colors;

export default function ShiftWarningModal({ handleVisibility }) {
  const { t } = useTranslation();

  return (
    <ModalSystem
      width={400}
      visible={true}
      setVisible={handleVisibility}
      theme="dark"
      showTitle={false}
      showIconTitle={false}
      onCancelFunction={() => handleVisibility()}>
      <div className="shiftWarningModal">
        <WarningNewIcon color={YELLOW} size={48} />
        <div className="shiftWarningModal-text">
          <p>
            {mismatchedMessageWithLink({
              t,
              source: 'edition'
            })}
          </p>
          <p>{t('master_plan_calendars.adjust')}</p>
        </div>
        <ButtonSystem onClick={() => handleVisibility()}>
          {t('master_plan_calendars.ok')}
        </ButtonSystem>
      </div>
    </ModalSystem>
  );
}

ShiftWarningModal.propTypes = {
  handleVisibility: PropTypes.func.isRequired
};
